<div class="grid">
	<div class="col-12">
		<div class="card">
			<h4>Display</h4>
			<p>Efficiently configure the display of the component with <a href="https://github.com/primefaces/primeflex">PrimeFlex</a> display utilities.</p>

			<h5>Inline</h5>
			<input type="text" pInputText class="mr-2 inline" />
			<input type="text" pInputText class="inline" />

			<h5>Block</h5>
			<input type="text" pInputText class="mb-2 block" />
			<input type="text" pInputText class="block" />

			<h5>Visible on a Small Screen</h5>
			<p>Resize to view.</p>
			<p-button type="button" icon="pi pi-bars" styleClass="p-button-rounded md:hidden"></p-button>

			<h5>Hidden on a Small Screen</h5>
			<p>Resize to hide.</p>
			<p-button type="button" icon="pi pi-search" styleClass="p-button-rounded p-button-success hidden md:inline-flex"></p-button>

			<h5>Visible to Print, Invisible for Screen</h5>
			<p class="hidden p-print-block">Only visible when printed.</p>

			<h5>Visible to Screen, Invisible for Print</h5>
			<p class="block p-print-none">Not available for printing.</p>
		</div>
	</div>
</div>
