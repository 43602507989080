import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AlertService, IAlertType } from '../services/alert-service';

export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private alertService: AlertService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(!request) {
            return EMPTY;
        }
        return next.handle(request)
            .pipe(
                // retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if(error?.error?.failures && error.error.failures.length > 0) {
                        error?.error?.failures.map(message => {
                            this.alertService.show({
                              severity: IAlertType.ERROR,
                              detail: message,
                              summary: 'Error',
                              sticky: true,
                            });
                          })
                    } else if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `${error.error.message}`;
                        this.alertService.show({
                            severity: IAlertType.ERROR,
                            detail: errorMessage,
                            summary: 'ERROR',
                        });
                    } else {
                        // server-side error
                        errorMessage = `Error Status: ${error.status}\nMessage: ${error?.error?.error} ? ${error.error.error} : ${error.message}`;
                        this.alertService.show({
                            severity: IAlertType.ERROR,
                            detail: errorMessage,
                            summary: 'ERROR',
                        });
                    }
                    return throwError(error);
                })
            );
    }
}
