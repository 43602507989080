import { Component, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppService } from './core/services/app.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styles: [`
    .layout-menu-wrapper .menu-logo .app-name img {
            height: 3.75rem;
            padding-left: 0;
    }


.layout-menu-wrapper .menu-logo .logo img {
    height: 4.25rem;
    border: 0 none;
    margin-left: -20px;
}
    `]
})
export class AppMenuComponent implements OnInit {
    public model: any[];

    constructor(public app: AppComponent, public appMain: AppMainComponent, public appService: AppService,) { }

    ngOnInit() {
        if (this.appService.activeProjectId) {
            this.appService.updateMenu(this.appService.activeProjectId);
        }
    }

}
