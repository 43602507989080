import { HttpParams, HttpHeaders } from '@angular/common/http';

export function toHttpParams(objectInstance: Record<string, any>, keys: any, value: any) {
    let httpParams: HttpParams = new HttpParams();
    if (!keys) {
        keys = [];
    }
    if (keys.length > 0 && value) {
        httpParams = httpParams.append(keys, value);
    } else {
        // for separate params other than filter ( Add Permissions, Add Policy and Mark duplicate )
        if (objectInstance instanceof Map) {
            for (const key of objectInstance.keys()) {
                httpParams = httpParams.append(key, objectInstance.get(key));
            }
        } else {
            for (const key in objectInstance) {
                if (objectInstance[key] !== null && objectInstance[key] !== undefined
                    && objectInstance[key] !== '' || objectInstance[key] === 0) {
                    // check for nested object if present function self call
                    if (typeof objectInstance[key] === 'object') {
                        httpParams = toHttpParams(objectInstance[key], keys.concat([key]), null);
                    } else {
                        // create custome param key
                        let paramKey: string = key;
                        if (keys && keys.length > 0) {
                            for (let index = 0; index < keys.length; index++) {
                                if (index === 0) {
                                    paramKey = keys[index];
                                } else {
                                    paramKey += '[' + keys[index] + ']';
                                }
                            }
                            paramKey += '[' + key + ']';
                        }
                        httpParams = httpParams.append(paramKey, objectInstance[key]);
                    }
                }
            }
        }
    }
    return httpParams;
}

export function createHttpHeaders() {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders = new HttpHeaders({
        content: 'application/json',
        'content-type': 'application/x-www-form-urlencoded',
    });
    return httpHeaders;
}
