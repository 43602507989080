<div [ngClass]="{'layout-inline-menu': true, 'layout-inline-menu-active':appMain.activeInlineProfile}" *ngIf="!appMain.isMobile() && (appMain.isStatic() || appMain.isSlim() || appMain.isSidebar())">
    <a *ngIf="authService.userProfile$ | async as profile" class="layout-inline-menu-action flex flex-row align-items-center" (click)="appMain.activeInlineProfile = !appMain.activeInlineProfile">
        <img src="{{profile.picture}}" alt="avatar" style="width: 44px; height: 44px;">
        <span class="layout-inline-menu-text flex flex-column ml-2">{{profile.name}}</span>
        <i class="layout-inline-menu-icon pi pi-angle-down ml-auto"></i>
    </a>

    <ul class="layout-inline-menu-action-panel" [@inline]="appMain.activeInlineProfile ? 'visibleAnimated' : 'hiddenAnimated'">
        <li class="layout-inline-menu-action-item" (click)="changeColorScheme()">
            <a class="flex flex-row align-items-center">
                <span class="p-2">Dark</span> <p-inputSwitch [(ngModel)]="lightMode"></p-inputSwitch> <span class="p-2">Light</span>
            </a>
        </li>
        <li class="layout-inline-menu-action-item" (click)="authService.logout()">
            <a class="flex flex-row align-items-center">
                <i class="pi pi-power-off pi-fw"></i>
                <span>Logout</span>
            </a>
        </li>
        <!-- <li class="layout-inline-menu-action-item">
            <a class="flex flex-row align-items-center">
                <i class="pi pi-cog pi-fw"></i>
                <span>Settings</span>
            </a>
        </li>
        <li class="layout-inline-menu-action-item">
            <a class="flex flex-row align-items-center">
                <i class="pi pi-user pi-fw"></i>
                <span>Profile</span>
            </a>
        </li> -->
    </ul>
</div>
