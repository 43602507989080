import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class AdminAuthGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {
        // localStorage.setItem('role', 'admin');
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
        return this.auth.isAuthenticated$.pipe(
            tap(loggedIn => {
                if (!loggedIn) {
                    this.auth.login(state.url);
                } else {
                    const role = localStorage.getItem('role');
                    if (next.data.role && next.data.role.indexOf(role) === -1) {
                        this.router.navigate([`/`]);
                        return false;
                    }
                }
                return true;
            })
        );
    }
}