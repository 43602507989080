import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { AuthService } from '../core/auth/auth.service';

@Component({
  selector: 'app-accessdenied',
  templateUrl: './app.accessdenied.component.html',
})
export class AppAccessdeniedComponent {
  constructor(public app: AppComponent, public authService: AuthService,
    private router: Router) {}

  goHome() {
    this.router.navigate(['/']);
  }
}
