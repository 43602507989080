import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
/**
 * Session storage service
 * Provides methods to get, set, remove, clear session storage items.
 */
export class SessionService {
    /**
     * set session storage item
     * @ param key
     * @ param value
     */
    setItem(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    /**
     * get session storage item
     * @ param key
     */
    getItem(key: string): any {
        let value: any = localStorage.getItem(key);
        // if(key === 'ActiveProjectId' && !value && window.location.hash.indexOf('projects') === -1) {
        //     const urlForProject = window.location.hash.split('/');
        //     if(urlForProject && urlForProject.length > 0) {
        //       const projId = urlForProject[urlForProject.length -1 ];  
        //       if(projId) {
        //         console.log(projId);
        //         this.setItem('ActiveProjectId', projId);
        //         value = localStorage.getItem('ActiveProjectId');
        //         return projId;
        //       } else {
        //         console.log('no project id', window.location);
        //       }
        //     }
        // }
        return value ? JSON.parse(value) : null;
    }

    /**
     * remove session storage item
     * @ param key
     */
    removeItem(key: string) {
        localStorage.removeItem(key);
    }

    /**
     * remove all session storage items
     */
    clear() {
        localStorage.clear();
    }
}
