<div class="exception-body accessdenied">
    <div class="exception-panel">
        <h1>ACCESS</h1>
        <h3>denied</h3>
        <p>You are not allowed to view this page.</p>
        <div class="flex flex-column justify-content-between">
            <button type="button" pButton label="Go back to home" (click)="goHome()"></button>
            <button type="button" pButton label="Logout" (click)="authService.logout()"></button>
        </div>
    </div>
    <div class="exception-footer">
        <img [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'" class="exception-logo"/>
        <img [src]="'assets/layout/images/appname-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'" class="exception-appname"/>
    </div>
</div>
