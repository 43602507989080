import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from 'src/app/core/http/http.service';
import {environment} from 'src/environments/environment';
import {Snapshot} from './snapshot.model';

@Injectable({
    providedIn: 'root',
})

export class SnapshotService extends HttpService {

    AddSnapshot(data: any): Observable<any> {
        return this.post(
            `${environment.baseUrls.DEV_URL}snapshots`, data
        );
    }

    getSnapshot(id: any): Observable<any> {
        return this.get(
            `${environment.baseUrls.DEV_URL}snapshots?projectId=${id}`,
            {},
            null,
            null
        );
    }

    deleteSnapshot(id: any) {
        return this.delete(`${environment.baseUrls.DEV_URL}snapshots/${id}`, {});
    }

    restoreChanges(id: any) {
        return this.post(`${environment.baseUrls.DEV_URL}snapshots/${id}/restore`, {});
    }

    getDifference(id: any) {
        return this.get(
            `${environment.baseUrls.DEV_URL}snapshots/${id}/diff`,
            {},
            null,
            null
        );
    }
}

