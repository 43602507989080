import { Component } from '@angular/core';
import { AppComponent } from './app.component';

@Component({
    selector: 'app-footer',
    styles: [`
    .layout-footer .footer-logo-container img {
        width: auto;
        height: 3.25rem;
    }
    `],
    template: `
        <div class="layout-footer">
            <div class="footer-logo-container">
                <img id="footer-logo"   [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'dark') + '.png'" alt="atlantis-layout"/>
                <div class="app-name-footer-wrapper">                
                    <span class="app-name">EMBARK</span>
                    <span class="app-name">Secure Simplify Transform</span>
               </div>
            </div>
            <span class="copyright">&#169; Comanage360 - {{year}}</span>
        </div>
    `
})
export class AppFooterComponent {
    year: any;
    constructor(public app: AppComponent) {
        this.year = new Date().getFullYear();
    }
}
