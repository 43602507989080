import { Component, OnDestroy, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AuthService } from './core/auth/auth.service';
import { AppService } from './core/services/app.service';
import { LoaderService } from './core/services/loader.service';
import { PendingChanges } from './core/services/pending-changes.service';
import { SessionService } from './core/services/session.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  menuMode = localStorage.getItem('menuMode') ? localStorage.getItem('menuMode') : 'sidebar';

  layout = 'blue';

  theme = 'blue';

  ripple: boolean;

  colorScheme = localStorage.getItem('colorScheme') ? localStorage.getItem('colorScheme') : 'dark';

  constructor(
    translate: TranslateService,
    private primengConfig: PrimeNGConfig, public authService: AuthService,
    private sessionService: SessionService, private appService: AppService, private loaderService: LoaderService,
    private pendingChanges: PendingChanges) {
    this.authService.userProfile$.subscribe(
      (profile) => {
        if (profile) {
          appService.userData = profile;
          this.sessionService.setItem('LoggedInUserName', profile.name);
          this.sessionService.setItem('LoggedInUserEmail', profile.email);
          this.sessionService.setItem('ProfilePicture', profile.picture);
          if (this.sessionService.getItem("ActiveProjectId")) {
            this.appService.isProjectSelected = true;
            this.appService.activeProjectId = this.sessionService.getItem("ActiveProjectId");
            if(this.sessionService.getItem('role') !== 'SUPER_ADMIN') {
              this.getPendingChanges();
            }
          }
        }
      }
    );
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.ripple = true;
    this.loading = false;
  }

  getPendingChanges() {
    this.pendingChanges.getPendingChanges({}).subscribe(data => {
      this.pendingChanges.isPending.next({ pendingChanges: data.pendingChanges });
    })
  }

  ngOnDestroy(): void {
    this.loaderService.isLoading.unsubscribe();
  }
}
