import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';

export enum IAlertType {
  INFO = 'info',
  WARNING = 'warn',
  SUCCESS = 'success',
  ERROR = 'error'
}

export interface IAlertConfig {
  detail: string;
  severity: IAlertType,
  summary: string,
  life?: number,
  sticky?:boolean,
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  shouldShowAlert = false;
  timer: any;
  alertConfig: IAlertConfig = {
    severity: IAlertType.INFO,
    detail: '',
    summary: '',
    life: 5000,
    sticky: false
  };
  close = new Subject<any>();

  constructor(private messageService: MessageService,) { }

  show(config: IAlertConfig): AlertService {
    this.shouldShowAlert = true;
    this.alertConfig = { ...this.alertConfig, ...config };
    if(this.alertConfig.life === 0) {
      delete this.alertConfig.life
    }
    if(this.alertConfig.sticky) {
      delete this.alertConfig.life;
    }
    // this.messageService.add({ severity: this.alertConfig.severity, summary: this.alertConfig.summary, detail: this.alertConfig.detail, life: this.alertConfig.life, sticky: this.alertConfig.sticky });
    this.messageService.add({ ...this.alertConfig });
    return this;
  }

  hide(): AlertService {
    this.shouldShowAlert = false;
    return this;
  }

  onClose(value: any): any {
    this.hide();
    this.close.next(value);
  }

  onClose$(): any {
    return this.close;
  }

}
