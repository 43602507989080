<div class="grid list-demo">
	<div class="col-12">
		<div class="card">
			<h5>DataView</h5>
			<p-dataView #dv [value]="products" [paginator]="true" [rows]="9" filterBy="name"
						[sortField]="sortField" [sortOrder]="sortOrder" layout="grid">
				<ng-template pTemplate="header">
					<div class="flex flex-column md:flex-row md:justify-content-between">
						<p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By Price" (onChange)="onSortChange($event)" styleClass="mb-2 md:mb-0"></p-dropdown>
						<span class="p-input-icon-left mb-2 md:mb-0">
                    <i class="pi pi-search"></i>
                    <input type="search" pInputText placeholder="Search by Name" (input)="dv.filter($event.target.value)">
                </span>
						<p-dataViewLayoutOptions></p-dataViewLayoutOptions>
					</div>
				</ng-template>
				<ng-template let-product pTemplate="listItem">
					<div class="col-12">
						<div class="product-list-item card">
							<img [src]="'assets/demo/images/product/' + product.image" [alt]="product.name"/>
							<div class="product-list-detail">
								<div class="product-name">{{product.name}}</div>
								<div class="product-description">{{product.description}}</div>
								<p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating>
								<i class="pi pi-tag product-category-icon"></i><span class="product-category">{{product.category}}</span>
							</div>
							<div class="product-list-action">
								<span class="product-price">${{product.price}}</span>
								<p-button icon="pi pi-shopping-cart" label="Add to Cart" [disabled]="product.inventoryStatus === 'OUTOFSTOCK'"></p-button>
								<span [class]="'product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span>
							</div>
						</div>
					</div>
				</ng-template>
				<ng-template let-product pTemplate="gridItem">
					<div class="col-12 md:col-4">
						<div class="product-grid-item card">
							<div class="product-grid-item-top">
								<div>
									<i class="pi pi-tag product-category-icon"></i>
									<span class="product-category">{{product.category}}</span>
								</div>
								<span [class]="'product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span>
							</div>
							<div class="product-grid-item-content">
								<img [src]="'assets/demo/images/product/' + product.image" [alt]="product.name"/>
								<div class="product-name">{{product.name}}</div>
								<div class="product-description">{{product.description}}</div>
								<p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating>
							</div>
							<div class="product-grid-item-bottom">
								<span class="product-price">${{product.price}}</span>
								<p-button icon="pi pi-shopping-cart" [disabled]="product.inventoryStatus === 'OUTOFSTOCK'"></p-button>
							</div>
						</div>
					</div>
				</ng-template>
			</p-dataView>
		</div>
	</div>

	<div class="col-12 lg:col-8">
		<div class="card">
			<h5>PickList</h5>
			<p-pickList [source]="sourceCities" [target]="targetCities" sourceHeader="From" targetHeader="To" dragdrop="true"
						[responsive]="true" [sourceStyle]="{'height':'300px'}" [targetStyle]="{'height':'300px'}">
				<ng-template let-city pTemplate="item">
					<div>{{city.name}}</div>
				</ng-template>
			</p-pickList>
		</div>
	</div>

	<div class="col-12 lg:col-4">
		<div class="card">
			<h5>OrderList</h5>
			<p-orderList [value]="orderCities" [listStyle]="{'height':'auto'}" header="Cities" [dragdrop]="true">
				<ng-template let-city pTemplate="item">
					<div>{{city.name}}</div>
				</ng-template>
			</p-orderList>
		</div>
	</div>
</div>
