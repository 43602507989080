import { Component } from '@angular/core';
import {AppMainComponent} from './app.main.component';
import { AppService } from './core/services/app.service';

@Component({
    selector: 'app-rightmenu',
    templateUrl: './app.rightmenu.component.html'
})
export class AppRightMenuComponent {
    date: Date;
    constructor(public appMain: AppMainComponent, public appService: AppService) {

    }
}
