import { Injectable } from "@angular/core";
import { SessionService } from "src/app/core/services/session.service";
@Injectable()
export class Utils {

    constructor(public sessionService: SessionService) {

    }
    static PHONE_REGEX = /[(][0-9]{3}[)][0-9]{3}-[0-9]{4}|^\d{3}-\d{3}-\d{4}$/;
    // tslint:disable-next-line:max-line-length
    static WEBSITE_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    static DEFAULT_PROFILE_IMG = 'assets/images/zportal-logo.png';
    static DEFAULT_PROMOTION_IMG = 'assets/images/placeholder.png';
    static DEFAULT_TENANT_LOGO = 'assets/images/SM_Logo.png';
    static APP_URL = 'https://zportal.io';


    getTabList() {
        return [
            {
                Icon: undefined,
                IsChildVisible: false,
                Label: "Dashboard",
                RouterLink: "/dashboard",
                mainTab: "Dashboard",
                Childs: []
            },
            {
                Icon: undefined,
                IsChildVisible: false,
                Label: "Projects",
                RouterLink: "/projects",
                mainTab: "Projects",
                Childs: []
            },
            {
                Icon: undefined,
                IsChildVisible: false,
                Label: "Files",
                RouterLink: `/files/${this.sessionService.getItem('ActiveProjectId')}`,
                mainTab: "Files",
                Childs: []
            },
            {
                Icon: undefined,
                IsChildVisible: false,
                Label: "Webfilter",
                RouterLink: `/webfilter/${this.sessionService.getItem('ActiveProjectId')}`,
                mainTab: "Webfilter",
                Childs: [
                    {
                        mainTab: '',
                        Childs: [],
                        Icon: '',
                        IsChildVisible: false,
                        Label: "URL Categories",
                        RouterLink: `/webfilter/filter/${this.sessionService.getItem('ActiveProjectId')}`
                    },
                    {
                        mainTab: '',
                        Childs: [],
                        Icon: '',
                        IsChildVisible: false,
                        Label: "Catch All",
                        RouterLink: `/webfilter/catchall/${this.sessionService.getItem('ActiveProjectId')}`
                    },
                    {
                        mainTab: '',
                        Childs: [],
                        Icon: '',
                        IsChildVisible: false,
                        Label: "URL Filtering Policies",
                        RouterLink: `/webfilter/ordering/${this.sessionService.getItem('ActiveProjectId')}`
                    }
                ],
            },
            {
                Icon: undefined,
                IsChildVisible: false,
                Label: "Firewall",
                RouterLink: `/firewall/${this.sessionService.getItem('ActiveProjectId')}`,
                mainTab: "Firewall",
                Childs: [
                    {
                        mainTab: '',
                        Childs: [],
                        Icon: '',
                        IsChildVisible: false,
                        Label: "Policies",
                        RouterLink: `/firewall/policies/${this.sessionService.getItem('ActiveProjectId')}`
                    },
                    {
                        mainTab: '',
                        Childs: [],
                        Icon: '',
                        IsChildVisible: false,
                        Label: "Address Group",
                        RouterLink: `/firewall/addressGroup/${this.sessionService.getItem('ActiveProjectId')}`
                    },
                    {
                        mainTab: '',
                        Childs: [],
                        Icon: '',
                        IsChildVisible: false,
                        Label: "Address",
                        RouterLink: `/firewall/address/${this.sessionService.getItem('ActiveProjectId')}`
                    },
                    {
                        mainTab: '',
                        Childs: [],
                        Icon: '',
                        IsChildVisible: false,
                        Label: "Services",
                        RouterLink: `/firewall/services/${this.sessionService.getItem('ActiveProjectId')}`
                    },
                    {
                        mainTab: '',
                        Childs: [],
                        Icon: '',
                        IsChildVisible: false,
                        Label: "AppID",
                        RouterLink: `/firewall/appID/${this.sessionService.getItem('ActiveProjectId')}`
                    },
                    {
                        mainTab: '',
                        Childs: [],
                        Icon: '',
                        IsChildVisible: false,
                        Label: "Interfaces",
                        RouterLink: `/firewall/Interfaces/${this.sessionService.getItem('ActiveProjectId')}`
                    }
                ],
            },
            // {
            //     Icon: undefined,
            //     IsChildVisible: false,
            //     Label: "Interfaces",
            //     RouterLink: `/interfaces/${this.sessionService.getItem('ActiveProjectId')}`,
            //     mainTab: "Interfaces",
            //     Childs: [
            //         {
            //             mainTab: '',
            //             Childs: [],
            //             Icon: '',
            //             IsChildVisible: false,
            //             Label: "Interfaces Settings",
            //             RouterLink: `/interfaces/Settings/${this.sessionService.getItem('ActiveProjectId')}`
            //         },
            //         {
            //             mainTab: '',
            //             Childs: [],
            //             Icon: '',
            //             IsChildVisible: false,
            //             Label: "Interfaces Entries",
            //             RouterLink: `/interfaces/Entries/${this.sessionService.getItem('ActiveProjectId')}`
            //         }
            //     ],
            // },
            {
                Icon: undefined,
                IsChildVisible: false,
                Label: "User Group",
                RouterLink: `/userGroup/${this.sessionService.getItem('ActiveProjectId')}`,
                mainTab: "User Group",
                Childs: [
                    {
                        mainTab: '',
                        Childs: [],
                        Icon: '',
                        IsChildVisible: false,
                        Label: "Users",
                        RouterLink: `/userGroup/users/${this.sessionService.getItem('ActiveProjectId')}`
                    },
                    {
                        mainTab: '',
                        Childs: [],
                        Icon: '',
                        IsChildVisible: false,
                        Label: "User Groups",
                        RouterLink: `/userGroup/groups/${this.sessionService.getItem('ActiveProjectId')}`
                    },
                    {
                        mainTab: '',
                        Childs: [],
                        Icon: '',
                        IsChildVisible: false,
                        Label: "Departments",
                        RouterLink: `/userGroup/department/${this.sessionService.getItem('ActiveProjectId')}`
                    }
                ],
            },
            {
                Icon: undefined,
                IsChildVisible: false,
                Label: "System",
                RouterLink: "/system",
                mainTab: "System",
                Childs: []
            },

        ]
    }
}


export enum TypeOption {
    ZPORTAL = 'ZPortal',
    MANUFACTURER = 'Manufacturer',
    DISTRIBUTOR = 'Distributor',
    RESELLER = 'Reseller',
    END_USER = 'End User'
}
