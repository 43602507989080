import { Component, OnInit } from '@angular/core';
import { EventService } from '../service/eventservice';
import { PrimeIcons } from 'primeng/api';
import { Customer } from '../domain/customer';
import { CustomerService } from '../service/customerservice';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
    templateUrl: './comanagedashboard.component.html',
    styleUrls: ['./tabledemo.scss']
})
export class ComanageDashboardComponent implements OnInit {
    revenueChart: any;
    revenueChartOptions: any;
    
    deviceChart: any;
    deviceChartOptions: any;
    deviceChartWeekly: any;
    deviceChartOptionsWeekly: any;
    deviceChartMonthly: any;
    deviceChartOptionsMonthly: any;
    
    deviceBarChart: any;
    deviceBarChartOptions: any;
    userLineChart: any;
    userLineChartOptions: any;
    userLineChartMonthly: any;
    userLineChartOptionsMonthly: any;
    deviceBarChartMonthly: any;
    deviceBarChartOptionsMonthly: any;


    visitorYear: any;
    selectedVisitorYear: any;
    visitorChart: any;
    visitorChartOptions: any;

    timelineEvents: any[];
    countryChart: any;
    countryChartOptions: any;
    selectedCustomers1: Customer[];

    orderYear: any;

    selectedOrderYear: any;

    revenueMonth: any;

    selectedRevenueMonth: any;



    customerYear: any;

    selectedCustomerYear: any;

    growth: any;

    avgCustomer: any;



    constructor(private customerService: CustomerService, private eventService: EventService,
        private breadcrumbService: AppBreadcrumbService, loaderService: LoaderService) {
        loaderService.isLoading.next(false);

        this.breadcrumbService.setItems([
            { label: 'Dashboard' },
            { label: 'Embark Dashboard', routerLink: ['/'] },
        ]);
    }

    ngOnInit() {


        this.visitorChart = {
            labels: ['Week1', 'Week2', 'Week3', 'Week4', 'Week5', 'Week6', 'Week7', 'Week8', 'Week9', 'Week10', 'Week11', 'Week12', 'Week13', 'Week14', 'Week15', 'Week16', 'Week17', 'Week18', 'Week19', 'Week20'],
            datasets: [
                {
                    label: 'Users',
                    data: [10, 12, 25, 50, 70, 80, 100, 175, 220, 220, 220, 230, 235, 240, 400, 450, 500, 550, 575, 625],
                    borderColor: [
                        '#FC6161',
                    ],
                    // pointBorderColor: 'transparent',
                    // pointBackgroundColor: 'transparent',
                    type: 'line',
                    fill: false,
                    barPercentage: 0.5,
                    // stepped: true
                    borderWidth: 2,
                },
                {
                    type: 'bar',
                    label: 'IOS',
                    data: [3.00, 3.60, 7.50, 15.00, 21.00, 24.00, 30.00, 52.50, 66.00, 66.00, 66.00, 69.00, 70.50, 72.00, 120.00, 135.00, 150.00, 165.00, 172.50, 187.50],
                    backgroundColor: getComputedStyle(document.body).getPropertyValue('--primary-color'),
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'ANDROID',
                    data: [1.00, 1.20, 2.50, 5.00, 7.00, 8.00, 10.00, 17.50, 22.00, 22.00, 22.00, 23.00, 23.50, 24.00, 40.00, 45.00, 50.00, 55.00, 57.50, 62.50],
                    backgroundColor: '#545C6B',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'MAC OS',
                    data: [4.00, 4.80, 10.00, 20.00, 28.00, 32.00, 40.00, 70.00, 88.00, 88.00, 88.00, 92.00, 94.00, 96.00, 160.00, 180.00, 200.00, 220.00, 230.00, 250.00],
                    backgroundColor: '#EC4DBC',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'WINDOWS OS',
                    data: [4.00, 4.80, 10.00, 20.00, 28.00, 32.00, 40.00, 70.00, 88.00, 88.00, 88.00, 92.00, 94.00, 96.00, 160.00, 180.00, 200.00, 220.00, 230.00, 250.00],
                    backgroundColor: '#EEE500',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'LINUX',
                    data: [1.00, 1.20, 2.50, 5.00, 7.00, 8.00, 10.00, 17.50, 22.00, 22.00, 22.00, 23.00, 23.50, 24.00, 40.00, 45.00, 50.00, 55.00, 57.50, 62.50],
                    backgroundColor: '#FC6161',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'OTHER OS',
                    data: [1.00, 1.20, 2.50, 5.00, 7.00, 8.00, 10.00, 17.50, 22.00, 22.00, 22.00, 23.00, 23.50, 24.00, 40.00, 45.00, 50.00, 55.00, 57.50, 62.50],
                    backgroundColor: '#00D0DE',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                }
            ]
        };

        this.visitorChartOptions = {
            plugins: {
                legend: {
                    position: 'top',
                    align: 'end'
                }
            },
            responsive: true,
            hover: {
                mode: 'index'
            },
            scales: {
                y: {
                    min: 0,
                    max: 700
                    ,
                    grid: {
                        display: false
                    }
                },
                x: {
                    barPercentage: 0.5,
                    grid: {
                        display: false
                    }
                }
            }
        };



        this.deviceBarChart = {
            labels: ['Week1', 'Week2', 'Week3', 'Week4', 'Week5', 'Week6', 'Week7', 'Week8', 'Week9', 'Week10', 'Week11', 'Week12', 'Week13', 'Week14', 'Week15', 'Week16', 'Week17', 'Week18', 'Week19', 'Week20'],
            datasets: [
                {
                    type: 'bar',
                    label: 'IOS',
                    data: [3.00, 3.60, 7.50, 15.00, 21.00, 24.00, 30.00, 52.50, 66.00, 66.00, 66.00, 69.00, 70.50, 72.00, 120.00, 135.00, 150.00, 165.00, 172.50, 187.50],
                    backgroundColor: getComputedStyle(document.body).getPropertyValue('--primary-color'),
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'ANDROID',
                    data: [1.00, 1.20, 2.50, 5.00, 7.00, 8.00, 10.00, 17.50, 22.00, 22.00, 22.00, 23.00, 23.50, 24.00, 40.00, 45.00, 50.00, 55.00, 57.50, 62.50],
                    backgroundColor: '#545C6B',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'MAC OS',
                    data: [4.00, 4.80, 10.00, 20.00, 28.00, 32.00, 40.00, 70.00, 88.00, 88.00, 88.00, 92.00, 94.00, 96.00, 160.00, 180.00, 200.00, 220.00, 230.00, 250.00],
                    backgroundColor: '#EC4DBC',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'WINDOWS OS',
                    data: [4.00, 4.80, 10.00, 20.00, 28.00, 32.00, 40.00, 70.00, 88.00, 88.00, 88.00, 92.00, 94.00, 96.00, 160.00, 180.00, 200.00, 220.00, 230.00, 250.00],
                    backgroundColor: '#EEE500',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'LINUX',
                    data: [1.00, 1.20, 2.50, 5.00, 7.00, 8.00, 10.00, 17.50, 22.00, 22.00, 22.00, 23.00, 23.50, 24.00, 40.00, 45.00, 50.00, 55.00, 57.50, 62.50],
                    backgroundColor: '#FC6161',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'OTHER OS',
                    data: [1.00, 1.20, 2.50, 5.00, 7.00, 8.00, 10.00, 17.50, 22.00, 22.00, 22.00, 23.00, 23.50, 24.00, 40.00, 45.00, 50.00, 55.00, 57.50, 62.50],
                    backgroundColor: '#00D0DE',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                }
            ]
        };

        this.deviceBarChartOptions = {
            plugins: {
                legend: {
                    position: 'top',
                    align: 'end'
                }
            },
            responsive: true,
            hover: {
                mode: 'index'
            },
            scales: {
                y: {
                    min: 0,
                    max: 700
                    ,
                    grid: {
                        display: false
                    }
                },
                x: {
                    barPercentage: 0.5,
                    grid: {
                        display: false
                    }
                }
            }
        };


        this.userLineChart = {
            labels: ['Week1', 'Week2', 'Week3', 'Week4', 'Week5', 'Week6', 'Week7', 'Week8', 'Week9', 'Week10', 'Week11', 'Week12', 'Week13', 'Week14', 'Week15', 'Week16', 'Week17', 'Week18', 'Week19', 'Week20'],
            datasets: [
                {
                    label: 'Users',
                    data: [10, 12, 25, 50, 70, 80, 100, 175, 220, 220, 220, 230, 235, 240, 400, 450, 500, 550, 575, 625],
                    borderColor: [
                        '#FC6161',
                    ],
                    type: 'line',
                    fill: false,
                    barPercentage: 0.5,
                    borderWidth: 2,
                }
            ]
        };

        this.userLineChartOptions = {
            plugins: {
                legend: {
                    position: 'top',
                    align: 'end'
                }
            },
            responsive: true,
            hover: {
                mode: 'index'
            },
            scales: {
                y: {
                    min: 0,
                    max: 700
                    ,
                    grid: {
                        display: false
                    }
                },
                x: {
                    barPercentage: 0.5,
                    grid: {
                        display: false
                    }
                }
            }
        };


        this.userLineChartMonthly = {
            labels: ['January', 'February', 'March', 'April', 'May'],
            datasets: [
                {
                    label: 'Users',
                    data: [97, 425, 890, 1325, 2250],
                    borderColor: [
                        '#FC6161',
                    ],
                    type: 'line',
                    fill: false,
                    barPercentage: 0.5,
                    borderWidth: 2,
                }
            ]
        };

        this.userLineChartOptionsMonthly = {
            plugins: {
                legend: {
                    position: 'top',
                    align: 'end'
                }
            },
            responsive: true,
            hover: {
                mode: 'index'
            },
            scales: {
                y: {
                    min: 0,
                    max: 2300
                    ,
                    grid: {
                        display: false
                    }
                },
                x: {
                    barPercentage: 0.5,
                    grid: {
                        display: false
                    }
                }
            }
        };



        this.deviceChartWeekly = {
            labels: ['Week1', 'Week2', 'Week3', 'Week4', 'Week5', 'Week6', 'Week7', 'Week8', 'Week9', 'Week10', 'Week11', 'Week12', 'Week13', 'Week14', 'Week15', 'Week16', 'Week17', 'Week18', 'Week19', 'Week20'],
            datasets: [
                {
                    label: 'Users',
                    data: [10, 12, 25, 50, 70, 80, 100, 175, 220, 220, 220, 230, 235, 240, 400, 450, 500, 550, 575, 625],
                    borderColor: [
                        '#FC6161',
                    ],
                    type: 'line',
                    fill: false,
                    barPercentage: 0.5,
                    borderWidth: 2,
                },
                {
                    type: 'bar',
                    label: 'IOS',
                    data: [3.00, 3.60, 7.50, 15.00, 21.00, 24.00, 30.00, 52.50, 66.00, 66.00, 66.00, 69.00, 70.50, 72.00, 120.00, 135.00, 150.00, 165.00, 172.50, 187.50],
                    backgroundColor: getComputedStyle(document.body).getPropertyValue('--primary-color'),
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'ANDROID',
                    data: [1.00, 1.20, 2.50, 5.00, 7.00, 8.00, 10.00, 17.50, 22.00, 22.00, 22.00, 23.00, 23.50, 24.00, 40.00, 45.00, 50.00, 55.00, 57.50, 62.50],
                    backgroundColor: '#545C6B',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'MAC OS',
                    data: [4.00, 4.80, 10.00, 20.00, 28.00, 32.00, 40.00, 70.00, 88.00, 88.00, 88.00, 92.00, 94.00, 96.00, 160.00, 180.00, 200.00, 220.00, 230.00, 250.00],
                    backgroundColor: '#EC4DBC',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'WINDOWS OS',
                    data: [4.00, 4.80, 10.00, 20.00, 28.00, 32.00, 40.00, 70.00, 88.00, 88.00, 88.00, 92.00, 94.00, 96.00, 160.00, 180.00, 200.00, 220.00, 230.00, 250.00],
                    backgroundColor: '#EEE500',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'LINUX',
                    data: [1.00, 1.20, 2.50, 5.00, 7.00, 8.00, 10.00, 17.50, 22.00, 22.00, 22.00, 23.00, 23.50, 24.00, 40.00, 45.00, 50.00, 55.00, 57.50, 62.50],
                    backgroundColor: '#FC6161',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'OTHER OS',
                    data: [1.00, 1.20, 2.50, 5.00, 7.00, 8.00, 10.00, 17.50, 22.00, 22.00, 22.00, 23.00, 23.50, 24.00, 40.00, 45.00, 50.00, 55.00, 57.50, 62.50],
                    backgroundColor: '#00D0DE',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                }
            ]
        };

        this.deviceChartMonthly = {
            labels: ['January', 'February', 'March', 'April', 'May'],
            datasets: [
                {
                    label: 'Users',
                    data: [97, 425, 890, 1325, 2250],
                    borderColor: [
                        '#FC6161',
                    ],
                    type: 'line',
                    fill: false,
                    barPercentage: 0.5,
                    borderWidth: 2,
                },
                {
                    type: 'bar',
                    label: 'IOS',
                    data: [29.10, 127.50, 337.50, 477.00, 525.00],
                    backgroundColor: getComputedStyle(document.body).getPropertyValue('--primary-color'),
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'ANDROID',
                    data: [9.70, 42.50, 89.00, 132.50, 225.00],
                    backgroundColor: '#545C6B',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'MAC OS',
                    data: [38.80, 170.00, 356.00,530.00, 900.00],
                    backgroundColor: '#EC4DBC',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'WINDOWS OS',
                    data: [38.80, 170.00, 356.00,530.00, 900.00],
                    backgroundColor: '#EEE500',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'LINUX',
                    data: [9.70, 42.50, 89.00, 132.50, 225.00],
                    backgroundColor: '#FC6161',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'OTHER OS',
                    data: [9.70, 42.50, 89.00, 132.50, 225.00],
                    backgroundColor: '#00D0DE',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                }
            ]
        };



        
        this.deviceChartOptionsWeekly = {
            plugins: {
                legend: {
                    position: 'top',
                    align: 'end'
                }
            },
            responsive: true,
            hover: {
                mode: 'index'
            },
            scales: {
                y: {
                    min: 0,
                    max: 700
                    ,
                    grid: {
                        display: false
                    }
                },
                x: {
                    barPercentage: 1.0,
                    grid: {
                        display: false
                    }
                }
            }
        };


        this.deviceBarChartMonthly = {
            labels: ['January', 'February', 'March', 'April', 'May'],
            datasets: [
                {
                    type: 'bar',
                    label: 'IOS',
                    data: [29.10, 127.50, 337.50, 477.00, 525.00],
                    backgroundColor: getComputedStyle(document.body).getPropertyValue('--primary-color'),
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'ANDROID',
                    data: [9.70, 42.50, 89.00, 132.50, 225.00],
                    backgroundColor: '#545C6B',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'MAC OS',
                    data: [38.80, 170.00, 356.00,530.00, 900.00],
                    backgroundColor: '#EC4DBC',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'WINDOWS OS',
                    data: [38.80, 170.00, 356.00,530.00, 900.00],
                    backgroundColor: '#EEE500',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'LINUX',
                    data: [9.70, 42.50, 89.00, 132.50, 225.00],
                    backgroundColor: '#FC6161',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                },
                {
                    type: 'bar',
                    label: 'OTHER OS',
                    data: [9.70, 42.50, 89.00, 132.50, 225.00],
                    backgroundColor: '#00D0DE',
                    fill: true,
                    barPercentage: 0.5,
                    stepped: true
                }
            ]
        };



        
        this.deviceBarChartOptionsMonthly = {
            plugins: {
                legend: {
                    position: 'top',
                    align: 'end'
                }
            },
            responsive: true,
            hover: {
                mode: 'index'
            },
            scales: {
                y: {
                    min: 0,
                    max: 700
                    ,
                    grid: {
                        display: false
                    }
                },
                x: {
                    barPercentage: 1.0,
                    grid: {
                        display: false
                    }
                }
            }
        };


        this.deviceChart = this.deviceChartWeekly;
        this.deviceChartOptions = this.deviceChartOptionsWeekly;

        this.growth = '$620,076';
        this.avgCustomer = '$1,120';

        this.timelineEvents = [
            {
                transaction: 'Payment from #28492', amount: '+$250.00', date: 'June 13, 2020 11:09 AM',
                icon: PrimeIcons.CHECK, iconColor: '#0F8BFD', amountColor: '#00D0DE'
            },
            {
                transaction: 'Process refund to #94830', amount: '-$570.00', date: 'June 13, 2020 08:22 AM',
                icon: PrimeIcons.REFRESH, iconColor: '#FC6161', amountColor: '#FC6161'
            },
            {
                transaction: 'New 8 user to #5849', amount: '+$50.00', date: 'June 12, 2020 02:56 PM',
                icon: PrimeIcons.PLUS, iconColor: '#0BD18A', amountColor: '#0BD18A'
            },
            {
                transaction: 'Payment from #3382', amount: '+$3830.00', date: 'June 11, 2020 06:11 AM',
                icon: PrimeIcons.CHECK, iconColor: '#0F8BFD', amountColor: '#00D0DE'
            },
            {
                transaction: 'Payment from #4738', amount: '+$845.00', date: 'June 11, 2020 03:50 AM',
                icon: PrimeIcons.CHECK, iconColor: '#0F8BFD', amountColor: '#00D0DE'
            },
            {
                transaction: 'Payment failed form #60958', amount: '$1450.00', date: 'June 10, 2020 07:54 PM',
                icon: PrimeIcons.EXCLAMATION_TRIANGLE, iconColor: '#EC4DBC', amountColor: '#EC4DBC'
            },
            {
                transaction: 'Payment from #5748', amount: '+$50.00', date: 'June 09, 2020 11:37 PM',
                icon: PrimeIcons.CHECK, iconColor: '#0F8BFD', amountColor: '#00D0DE'
            },
            {
                transaction: 'Removed 32 users from #5849', amount: '-$240.00', date: 'June 09, 2020 08:40 PM',
                icon: PrimeIcons.MINUS, iconColor: '#FC6161', amountColor: '#FC6161'
            },
        ];

        this.countryChart = {
            labels: ['IOS', 'ANDROID', 'MAC', 'WINDOWS', 'LINUX'],
            datasets: [
                {
                    data: [250, 250, 250, 200, 50],
                    backgroundColor: [
                        '#0F8BFD',
                        '#545C6B',
                        '#EC4DBC',
                        '#EEE500',
                        '#FC6161',
                        '#00D0DE',
                        '#873EFE',
                    ],
                    hoverBackgroundColor: [
                        '#0F8BFD',
                        '#545C6B',
                        '#EC4DBC',
                        '#EEE500',
                        '#FC6161',
                        '#00D0DE',
                        '#873EFE',
                    ],
                    borderColor: 'transparent',
                    fill: true
                }
            ]
        };

        this.countryChartOptions = {};

        this.revenueChart = {
            labels: ['Week1', 'Week2', 'Week3', 'Week4', 'Week5', 'Week6', 'Week7', 'Week8', 'Week9', 'Week10', 'Week11', 'Week12', 'Week13', 'Week14', 'Week15', 'Week16', 'Week17', 'Week18', 'Week19', 'Week20'],
            datasets: [
                {
                    label: 'Users',
                    data: [10, 12, 25, 50, 70, 80, 100, 175, 220, 220, 220, 230, 235, 240, 400, 450, 500, 550, 575, 625],
                    borderColor: '#873EFE',
                    pointBackgroundColor: '#873EFE',
                    backgroundColor: '#873EFE',
                    fill: false,
                },
                // {
                //     label: 'IOS',
                //     data: [3.00, 3.60, 7.50, 15.00, 21.00, 24.00, 30.00, 52.50, 66.00, 66.00, 66.00, 69.00, 70.50, 72.00, 120.00, 135.00, 150.00, 165.00, 172.50, 187.50],
                //     borderColor: '#0F8BFD',
                //     pointBackgroundColor: '#0F8BFD',
                //     backgroundColor: 'rgba(0, 208, 222, 0.05)',
                //     fill: true,
                //     tension: .4
                // },
                // {
                //     label: 'ANDROID',
                //     data: [1.00, 1.20, 2.50, 5.00, 7.00, 8.00, 10.00, 17.50, 22.00, 22.00, 22.00, 23.00, 23.50, 24.00, 40.00, 45.00, 50.00, 55.00, 57.50, 62.50],
                //     borderColor: '#545C6B',
                //     pointBackgroundColor: '#545C6B',
                //     backgroundColor: 'rgba(253, 72, 74, 0.05)',
                //     fill: true,
                //     tension: .4
                // },
                // {
                //     label: 'MAC OS',
                //     data: [4.00, 4.80, 10.00, 20.00, 28.00, 32.00, 40.00, 70.00, 88.00, 88.00, 88.00, 92.00, 94.00, 96.00, 160.00, 180.00, 200.00, 220.00, 230.00, 250.00],
                //     borderColor: '#EC4DBC',
                //     pointBackgroundColor: '#EC4DBC',
                //     backgroundColor: 'rgba(15, 139, 253, 0.05)',
                //     fill: true,
                //     tension: .4
                // },
                // {
                //     label: 'WINDOWS OS',
                //     data: [4.00, 4.80, 10.00, 20.00, 28.00, 32.00, 40.00, 70.00, 88.00, 88.00, 88.00, 92.00, 94.00, 96.00, 160.00, 180.00, 200.00, 220.00, 230.00, 250.00],
                //     borderColor: '#EEE500',
                //     pointBackgroundColor: '#EEE500',
                //     backgroundColor: 'rgba(15, 139, 253, 0.05)',
                //     fill: true,
                //     tension: .4
                // },{
                //     label: 'LINUX',
                //     data: [1.00, 1.20, 2.50, 5.00, 7.00, 8.00, 10.00, 17.50, 22.00, 22.00, 22.00, 23.00, 23.50, 24.00, 40.00, 45.00, 50.00, 55.00, 57.50, 62.50],
                //     borderColor: '#FC6161',
                //     pointBackgroundColor: '#FC6161',
                //     backgroundColor: 'rgba(15, 139, 253, 0.05)',
                //     fill: true,
                //     tension: .4
                // },
                // {
                //     label: 'OTHER OS',
                //     data: [1.00, 2.20, 3.50, 6.00, 9.00, 8.00, 10.00, 17.50, 22.00, 22.00, 22.00, 25.00, 23.50, 28.00, 46.00, 49.00, 50.00, 55.00, 57.50, 65.50],
                //     borderColor: '#00D0DE',
                //     pointBackgroundColor: '#00D0DE',
                //     backgroundColor: 'rgba(15, 139, 253, 0.05)',
                //     fill: true,
                //     tension: .4
                // }
            ]
        };

        this.revenueChartOptions = {
            responsive: true,
            hover: {
                mode: 'index'
            },
            scales: {
                y: {
                    ticks: {
                        min: 0,
                        max: 60,
                        stepSize: 5
                    }
                }
            }
        };



        this.orderYear = [
            { name: '2021', code: '0' },
            { name: '2020', code: '1' }
        ];

        this.visitorYear = [
            { name: 'Weekly', code: '0' },
            { name: 'Monthly', code: '1' }
        ];

        this.customerYear = [
            { name: '2020', code: '0' },
            { name: '2019', code: '1' }
        ];

        this.revenueMonth = [
            { name: 'January - July 2021', code: '0' },
            { name: 'August - December 2020', code: '1' }
        ];
    }

    changeRevenueChart(event) {
        const dataSet1 = [
            [37, 34, 21, 27, 10, 18, 15],
            [31, 27, 30, 37, 23, 29, 20],
            [21, 7, 13, 3, 19, 11, 6],
            [47, 31, 35, 20, 46, 39, 25]
        ];
        const dataSet2 = [
            [31, 27, 30, 37, 23, 29, 20],
            [47, 31, 35, 20, 46, 39, 25],
            [37, 34, 21, 27, 10, 18, 15],
            [21, 7, 13, 3, 19, 11, 6]
        ];

        if (event.value.code === '1') {
            this.revenueChart.datasets[0].data = dataSet2[parseInt('0')];
            this.revenueChart.datasets[1].data = dataSet2[parseInt('1')];
            this.revenueChart.datasets[2].data = dataSet2[parseInt('2')];
            this.revenueChart.datasets[3].data = dataSet2[parseInt('3')];
        } else {
            this.revenueChart.datasets[0].data = dataSet1[parseInt('0')];
            this.revenueChart.datasets[1].data = dataSet1[parseInt('1')];
            this.revenueChart.datasets[2].data = dataSet1[parseInt('2')];
            this.revenueChart.datasets[3].data = dataSet1[parseInt('3')];
        }
    }

    changeVisitorChart(event) {
        if(event.value.name === 'Monthly') {
            this.visitorChart = this.deviceChartMonthly;
            this.visitorChartOptions = this.deviceChartOptionsMonthly;
        } else {
            this.visitorChart = this.deviceChartWeekly;
            this.visitorChartOptions = this.deviceChartOptionsWeekly;
        }
    }

    changeCustomerChart(event) {
        const dataSet1 = [
            [10, 25, 48, 35, 54, 70],
            [18, 35, 23, 30, 59, 65],
            [20, 47, 46, 46, 61, 70],
            [17, 34, 18, 48, 67, 68],
            [9, 37, 47, 50, 60, 62],
            [8, 48, 40, 52, 72, 75],
            [10, 18, 50, 47, 63, 80],
            [20, 36, 39, 58, 59, 85],
            [30, 45, 35, 50, 54, 81],
            [28, 35, 52, 56, 60, 77],
            [40, 40, 38, 45, 68, 86],
            [50, 23, 27, 34, 65, 90],
            [29, 27, 29, 42, 55, 84],
            [10, 37, 47, 29, 59, 80],
            [10, 54, 42, 38, 63, 83],
            [25, 44, 50, 56, 65, 92],
            [30, 43, 48, 45, 73, 78],
            [29, 47, 54, 60, 77, 86]
        ];
        const dataSet2 = [
            [10, 25, 48, 35, 54, 70],
            [20, 47, 46, 46, 61, 70],
            [17, 34, 18, 48, 67, 68],
            [50, 23, 27, 34, 65, 90],
            [8, 48, 40, 52, 72, 75],
            [9, 37, 47, 50, 60, 62],
            [10, 18, 50, 47, 63, 80],
            [30, 45, 35, 50, 54, 81],
            [10, 37, 47, 29, 59, 80],
            [28, 35, 52, 56, 60, 77],
            [25, 44, 50, 56, 65, 92],
            [18, 35, 23, 30, 59, 65],
            [20, 36, 39, 58, 59, 85],
            [29, 27, 29, 42, 55, 84],
            [40, 40, 38, 45, 68, 86],
            [30, 43, 48, 45, 73, 78],
            [10, 54, 42, 38, 63, 83],
            [29, 47, 54, 60, 77, 86]
        ];
    }

    recentSales(event) {
        if (event.value.code === '0') {
        } else {
        }
    }
}
