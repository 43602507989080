<div class="layout-dashboard">
    <div class="grid">
        <div class="col-12 md:col-6">
            <div class="card widget-overview-box widget-overview-box-2">
                <span class="overview-title">
                    LICENSE PURCHASED / LICENSE CONSUMED
                </span>
                <div class="flex justify-content-between">
                    <div class="overview-detail flex justify-content-between">
                        <div class="overview-badge flex justify-content-center align-items-center">
                            <span>62.5%</span>
                        </div>
                        <div class="overview-text">
                            1000 / 625
                        </div>
                    </div>
                </div>
                <!-- <img src="assets/layout/images/dashboard/value.svg"> -->
            </div>
        </div>
        <div class="col-12 md:col-6">
            <div class="card widget-overview-box widget-overview-box-2">
                <span class="overview-title">
                    NEW USERS (LAST 7 DAYS)
                </span>
                <div class="flex justify-content-between">
                    <div class="overview-detail flex justify-content-between">
                        <div class="overview-badge flex justify-content-center align-items-center">
                            <i class="pi pi-arrow-up"></i>
                            <span>8.69%</span>
                        </div>
                        <div class="overview-text">
                            77
                        </div>
                    </div>
                </div>
                <img src="assets/layout/images/dashboard/value.svg">
            </div>
        </div>
        <!-- <div class="col-12 md:col-4">
            <div class="card widget-overview-box widget-overview-box-1">
                <span class="overview-title">
                    LICENSE PURCHASED
                </span>
                <div class="flex justify-content-between">
                    <div class="overview-detail flex justify-content-between">
                        <div class="overview-badge flex justify-content-center align-items-center">
                            <i class="pi pi-arrow-down"></i>
                            <span>0.6%</span>
                        </div>
                        <div class="overview-text">
                            1000
                        </div>
                    </div>
                </div>
                <img src="assets/layout/images/dashboard/rate.svg">
            </div>
        </div>
        <div class="col-12 md:col-4">
            <div class="card widget-overview-box widget-overview-box-2">
                <span class="overview-title">
                    AVG. ORDER VALUE
                </span>
                <div class="flex justify-content-between">
                    <div class="overview-detail flex justify-content-between">
                        <div class="overview-badge flex justify-content-center align-items-center">
                            <i class="pi pi-arrow-up"></i>
                            <span>4,2%</span>
                        </div>
                        <div class="overview-text">
                            $306.2
                        </div>
                    </div>
                </div>
                <img src="assets/layout/images/dashboard/value.svg">
            </div>
        </div>
        <div class="col-12 md:col-4">
            <div class="card widget-overview-box widget-overview-box-3">
                <span class="overview-title">
                    ORDER QUANTITY
                </span>
                <div class="flex justify-content-between">
                    <div class="overview-detail flex justify-content-between">
                        <div class="overview-badge flex justify-content-center align-items-center">
                            <i class="pi pi-minus"></i>
                            <span>2,1%</span>
                        </div>
                        <div class="overview-text">
                            1,620
                        </div>
                    </div>
                </div>
                <img src="assets/layout/images/dashboard/quantity.svg">
            </div>
        </div> -->

        <div class="col-12 md:col-8">
            <div class="card widget-visitor-graph">
                <div class="card-header">
                    <span>USERS & DEVICES REPORT</span>
                    <p-dropdown [options]="visitorYear" [(ngModel)]="selectedVisitorYear" optionLabel="name"
                        (onChange)="changeVisitorChart($event); visitor.refresh()"></p-dropdown>
                </div>

                <!-- <div class="graph-content grid">
                    <div class="col-12 md:col-6">
                        <h2>{{growth}}</h2>
                        <h6>MRR GROWTH</h6>
                        <p>Measure how fast you're growing mothly recurring revenue. <a href="#">Learn more</a></p>
                    </div>
                    <div class="col-12 md:col-6">
                        <h2>{{avgCustomer}}</h2>
                        <h6>AVG. MRR/CUSTOMER</h6>
                        <p>The revenue generated per account on a monthly or yearly basis. <a href="#">Learn more</a></p>
                    </div>
                </div> -->

                <div class="graph">
                    <!-- <h6>Revenue</h6> -->

                    <p-chart #visitor type="bar" [data]="visitorChart" [options]="visitorChartOptions"
                        id="visitor-chart"></p-chart>
                </div>
            </div>
        </div>

        <!-- <div class="col-12 md:col-4">
            <div class="card widget-timeline">
                <div class="timeline-header flex justify-content-between align-items-center">
                    <p>Transaction history</p>
                    <div class="header-icons">
                        <i class="pi pi-refresh"></i>
                        <i class="pi pi-filter"></i>
                    </div>
                </div>
                <div class="timeline-content">
                    <p-timeline [value]="timelineEvents" styleClass="custimized-timeline">
                        <ng-template pTemplate="marker" let-event>
                            <span class="custom-marker" [style.backgroundColor]="event.iconColor">
                                <i [ngClass]="event.icon"></i>
                            </span>
                        </ng-template>
                        <ng-template pTemplate="content" let-event>
                            <div class="flex align-items-center justify-content-between">
                                <p>{{event.transaction}}</p>
                                <h6 [style.color]="event.amountColor">{{event.amount}}</h6>
                            </div>
                            <span>{{event.date}}</span>
                        </ng-template>
                    </p-timeline>
                </div>
                <div class="timeline-footer flex align-items-center justify-content-center">
                    <a href="#">View all transactions <i class="pi pi-arrow-down"></i></a>
                </div>
            </div>
        </div> -->

        <div class="col-12 md:col-4">
            <div class="card widget-country-graph">
                <div class="country-title">USERS DEVICES: 1000</div>
                <div class="country-graph">
                    <div class="flex justify-content-center">
                        <p-chart type="doughnut" id="country-chart" [data]="countryChart"
                            [options]="countryChartOptions" [style]="{'width': '75%'}"></p-chart>
                    </div>
                </div>
                <div class="country-content">
                    <ul>
                        <!-- <li class="flex justify-content-between align-items-center">
                            <div class="flex justify-content-between align-items-center">
                                <div class="color" style="background-color: #00D0DE; box-shadow: 0px 0px 10px rgba(0, 208, 222, 0.3);"></div>
                                <span>United States of America</span>
                            </div>
                            <span>25%</span>
                        </li>
                        <li class="flex justify-content-between align-items-center">
                            <div class="flex justify-content-between align-items-center">
                                <div class="color" style="background-color: #873EFE; box-shadow: 0px 0px 10px rgba(135, 62, 254, 0.3)"></div>
                                <span>China</span>
                            </div>
                            <span>20%</span>
                        </li> -->
                        <li class="flex justify-content-between align-items-center">
                            <div class="flex justify-content-between align-items-center">
                                <div class="color"
                                    style="background-color: #FC6161; box-shadow: 0px 0px 10px rgba(252, 97, 97, 0.3)">
                                </div>
                                <span>LINUX</span>
                            </div>
                            <span>5%</span>
                        </li>
                        <li class="flex justify-content-between align-items-center">
                            <div class="flex justify-content-between align-items-center">
                                <div class="color"
                                    style="background-color: #EEE500; box-shadow: 0px 0px 10px rgba(238, 229, 0, 0.3)">
                                </div>
                                <span>WINDOWS</span>
                            </div>
                            <span>20%</span>
                        </li>
                        <li class="flex justify-content-between align-items-center">
                            <div class="flex justify-content-between align-items-center">
                                <div class="color"
                                    style="background-color: #EC4DBC; box-shadow: 0px 0px 10px rgba(236, 77, 188, 0.3)">
                                </div>
                                <span>MAC</span>
                            </div>
                            <span>25%</span>
                        </li>
                        <li class="flex justify-content-between align-items-center">
                            <div class="flex justify-content-between align-items-center">
                                <div class="color"
                                    style="background-color: #0F8BFD; box-shadow: 0px 0px 10px rgba(15, 139, 253, 0.3)">
                                </div>
                                <span>IOS</span>
                            </div>
                            <span>25%</span>
                        </li>
                        <li class="flex justify-content-between align-items-center">
                            <div class="flex justify-content-between align-items-center">
                                <div class="color" style="background-color: #545C6B"></div>
                                <span>ANDROID</span>
                            </div>
                            <span>25%</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-12 md:col-6">
            <div class="card widget-revenue-graph">
                <div class="card-header">
                    <span>DEVICES REPORT MONTHLY</span>
                </div>
                <div class="graph">
                    <p-chart #device type="bar" [data]="deviceBarChartMonthly" [options]="deviceBarChartOptionsMonthly"
                        id="device-bar-chart"></p-chart>
                </div>
            </div>
        </div>
    
        <div class="col-12 md:col-6">
            <div class="card widget-revenue-graph">
                <div class="card-header">
                    <span>USERS REPORT MONTHLY</span>
                </div>
                <div class="graph">
                    <p-chart #revenue type="line" id="user-line-chart" [data]="userLineChartMonthly"
                        [options]="userLineChartOptionsMonthly"></p-chart>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-6">
            <div class="card widget-revenue-graph">
                <div class="card-header">
                    <span>DEVICES REPORT</span>
                </div>
                <div class="graph">
                    <p-chart #device type="bar" [data]="deviceBarChart" [options]="deviceBarChartOptions"
                        id="device-bar-chart"></p-chart>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-6">
            <div class="card widget-revenue-graph">
                <div class="card-header">
                    <span>USERS REPORT</span>
                </div>
                <div class="graph">
                    <p-chart #revenue type="line" id="user-line-chart" [data]="userLineChart"
                        [options]="userLineChartOptions"></p-chart>
                </div>
            </div>
        </div>
    </div>
</div>
