<h4>Reusable CSS widgets for your applications.</h4>

<div class="grid">

    <div class="col-12 md:col-4">
        <div class="card widget-overview-box widget-overview-box-1">
                <span class="overview-title">
                    CONVERSATION RATE
                </span>
            <div class="flex justify-content-between">
                <div class="overview-detail flex justify-content-between">
                    <div class="overview-badge flex justify-content-center align-items-center">
                        <i class="pi pi-arrow-down"></i>
                        <span>0.6%</span>
                    </div>
                    <div class="overview-text">
                        0.81%
                    </div>
                </div>
            </div>
            <img src="assets/layout/images/dashboard/rate.svg">
        </div>
    </div>
    <div class="col-12 md:col-4">
        <div class="card widget-overview-box widget-overview-box-2">
                <span class="overview-title">
                    AVG. ORDER VALUE
                </span>
            <div class="flex justify-content-between">
                <div class="overview-detail flex justify-content-between">
                    <div class="overview-badge flex justify-content-center align-items-center">
                        <i class="pi pi-arrow-up"></i>
                        <span>4,2%</span>
                    </div>
                    <div class="overview-text">
                        $306.2
                    </div>
                </div>
            </div>
            <img src="assets/layout/images/dashboard/value.svg">
        </div>
    </div>
    <div class="col-12 md:col-4">
        <div class="card widget-overview-box widget-overview-box-3">
                <span class="overview-title">
                    ORDER QUANTITY
                </span>
            <div class="flex justify-content-between">
                <div class="overview-detail flex justify-content-between">
                    <div class="overview-badge flex justify-content-center align-items-center">
                        <i class="pi pi-minus"></i>
                        <span>2,1%</span>
                    </div>
                    <div class="overview-text">
                        1,620
                    </div>
                </div>
            </div>
            <img src="assets/layout/images/dashboard/quantity.svg">
        </div>
    </div>

    <div class="col-12 md:col-4">
        <div class="card widget-timeline">
            <div class="timeline-header flex justify-content-between align-items-center">
                <p>Transaction history</p>
                <div class="header-icons">
                    <i class="pi pi-refresh"></i>
                    <i class="pi pi-filter"></i>
                </div>
            </div>
            <div class="timeline-content">
                <p-timeline [value]="timelineEvents" styleClass="custimized-timeline">
                    <ng-template pTemplate="marker" let-event>
                            <span class="custom-marker" [style.backgroundColor]="event.iconColor">
                                <i [ngClass]="event.icon"></i>
                            </span>
                    </ng-template>
                    <ng-template pTemplate="content" let-event>
                        <div class="flex align-items-center justify-content-between">
                            <p>{{event.transaction}}</p>
                            <h6 [style.color]="event.amountColor">{{event.amount}}</h6>
                        </div>
                        <span>{{event.date}}</span>
                    </ng-template>
                </p-timeline>
            </div>
            <div class="timeline-footer flex align-items-center justify-content-center">
                <span>View all transactions <i class="pi pi-arrow-down"></i></span>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-8">
        <div class="card widget-visitor-graph">
            <div class="card-header">
                <span>Unique visitor graph</span>
                <div class="actions">
                    <span>Yearly<i class="pi pi-angle-down"></i></span>
                    <span>2019<i class="pi pi-angle-down"></i></span>
                </div>
            </div>

            <div class="graph-content grid">
                <div class="col-12 md:col-6">
                    <h2>$620,076</h2>
                    <h6>MRR GROWTH</h6>
                    <p>Measure how fast you’re growing mothly recurring revenue. <a href="#">Learn more</a></p>
                </div>
                <div class="col-12 md:col-6">
                    <h2>$1,120</h2>
                    <h6>AVG. MRR/CUSTOMER</h6>
                    <p>The revenue generated per account on a monthly or yearly basis.<a href="#">Learn more</a></p>
                </div>
            </div>

            <div class="graph">
                <h6>Revenue</h6>

                <p-chart type="bar" [data]="visitorChart" [options]="visitorChartOptions" id="visitor-chart"></p-chart>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-8">
        <div class="card widget-customer-graph">
            <div class="header">
                <div class="title">
                    <span>Weekly new customers</span>
                    <span>2019<i class="pi pi-angle-down"></i></span>
                </div>
                <p class="subtitle">Number of new customer are listed by weekly</p>
            </div>

            <div class="content grid p-nogutter">
                <div class="col-12 md:col-6 grid">
                    <div class="col-12 md:col-4 flex align-items-center">
                        <h2>1232</h2>
                        <p>MAX</p>
                    </div>
                    <div class="col-12 md:col-4 flex align-items-center">
                        <h2>284</h2>
                        <p>MIN</p>
                    </div>
                    <div class="col-12 md:col-4 flex align-items-center">
                        <h2 style="color:#FC6161">875</h2>
                        <p>AVARAGE</p>
                    </div>
                </div>
            </div>

            <p-chart type="bar" id="customer-chart" [data]="customerChart" [options]="customerChartOptions"></p-chart>
        </div>
    </div>

    <div class="col-12 md:col-4">
        <div class="card widget-performance">
            <div class="header">
                <span>Quarterly win</span>
                <p class="subtitle">Top performances</p>
            </div>
            <div class="content">
                <ul>
                    <li class="person-item">
                        <p-avatar image="assets/layout/images/dashboard/ann.png" pBadge value="1" styleClass="mr-2" shape="circle"></p-avatar>
                        <div class="person-info">
                            <div class="amount">$94,815</div>
                            <div class="name">Ann Vaccaro</div>
                        </div>
                    </li>
                    <li class="person-item">
                        <p-avatar image="assets/layout/images/dashboard/miracle.png" pBadge value="2" styleClass="mr-2" shape="circle"></p-avatar>
                        <div class="person-info">
                            <div class="amount">$78,985</div>
                            <div class="name">Miracle Aminoff</div>
                        </div>
                    </li>
                    <li class="person-item">
                        <p-avatar image="assets/layout/images/dashboard/kaylynn.png" pBadge value="3" styleClass="mr-2" shape="circle"></p-avatar>
                        <div class="person-info">
                            <div class="amount">$53,611</div>
                            <div class="name">Kaylynn Geidt</div>
                        </div>
                    </li>
                    <li class="person-item">
                        <p-avatar image="assets/layout/images/dashboard/angel.png" pBadge value="4" styleClass="mr-2" shape="circle"></p-avatar>
                        <div class="person-info">
                            <div class="amount">$25,338</div>
                            <div class="name">Angel Rosser</div>
                        </div>
                    </li>
                    <li class="person-item">
                        <p-avatar image="assets/layout/images/dashboard/cristofer.png" pBadge value="5" styleClass="mr-2" shape="circle"></p-avatar>
                        <div class="person-info">
                            <div class="amount">$15,989</div>
                            <div class="name">Cristofer Mango</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-4">
        <div class="card widget-target">
            <div class="card-header">
                <span>Weekly target</span>
            </div>
            <div class="content">
                <h3>1232 Users</h3>
                <span class="rate">%3.5 <i class="pi pi-arrow-up"></i><span>  than last week</span></span>
            </div>
            <div class="values">
                <div class="item">
                    <span>51%</span>
                    <p-progressBar [value]="51" [showValue]="false"></p-progressBar>
                    <span class="day">Thu</span>
                </div>
                <div class="item">
                    <span>68%</span>
                    <p-progressBar [value]="68" [showValue]="false"></p-progressBar>
                    <span class="day">Fri</span>
                </div>
                <div class="item">
                    <span>74%</span>
                    <p-progressBar [value]="74" [showValue]="false"></p-progressBar>
                    <span class="day">Sat</span>
                </div>
                <div class="item">
                    <span>61%</span>
                    <p-progressBar [value]="61" [showValue]="false"></p-progressBar>
                    <span class="day">Sun</span>
                </div>
                <div class="item success">
                    <span>100%</span>
                    <p-progressBar [value]="100" [showValue]="false"></p-progressBar>
                    <span class="day">Mon</span>
                </div>
                <div class="item">
                    <span>70%</span>
                    <p-progressBar [value]="70" [showValue]="false"></p-progressBar>
                    <span class="day">Tue</span>
                </div>
                <div class="item today">
                    <span>22%</span>
                    <p-progressBar [value]="22" [showValue]="false"></p-progressBar>
                    <span class="day">Today</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-8">
        <div class="card widget-revenue-graph">
            <div class="card-header">
                <span>Montly revenue</span>
                <div class="actions">
                    <span>January - July 2020<i class="pi pi-angle-down"></i></span>
                </div>
            </div>

            <div class="graph">
                <h6>Revenue</h6>

                <p-chart type="line" id="revenue-chart" [data]="revenueChart" [options]="revenueChartOptions"></p-chart>
            </div>
        </div>
    </div>
</div>
