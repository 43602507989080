// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const MASTER_BASE = 'https://trust-tamer-backend.dev-comanage360.com';
// const MASTER_BASE = 'http://localhost:3000';

export const environment = {
  production: false,
  version: '1.0',
  environmentName: 'Local',
  apiUrl: 'https://frontend-erzlpu6n2a-uc.a.run.app',
  auth: {
    clientID: 'qIpaFEzCpBLvR3YkZWoAJiixYqhcK27q',
    domain: 'zportal-dev.us.auth0.com',
    audience: 'https://zportal-dev.us.auth0.com/api/v2/',
    scope: 'openid profile email'
  },
  baseUrls: {
    MICROSERVICE_URL: `${MASTER_BASE}/`,
    PROJECT_URL: `${MASTER_BASE}/projects`,
    FILE_URL: `${MASTER_BASE}/files/`,
    // FIREWALL_POLICY: `${MASTER_BASE}/firewall-policies/`,
    FIREWALL_POLICY: 'https://firewall-policy.dev-comanage360.com/',
    FIREWALL_NW_SERVICE: `${MASTER_BASE}/firewall-services/`,
    FIREWALL_ADDRESS_OBJECT: `${MASTER_BASE}/address-objects/`,
    // FIREWALL_ADDRESS_OBJECT: 'https://address-object.dev-comanage360.com/',
    FIREWALL_ADDRESS_GROUP: `${MASTER_BASE}/address-groups/`,
    FIREWALL_NW_INTERFACE: `${MASTER_BASE}/nw-interfaces/`,
    ZIA_INTEGRATION_URL: `${MASTER_BASE}/zia/`,
    ZPA_INTEGRATION_URL: `${MASTER_BASE}/zpa/`,
    USER_URL: `${MASTER_BASE}/users/`,
    PENDING_CHANGE_URL: `${MASTER_BASE}/pending-changes/`,
    URL_FILTERING: `${MASTER_BASE}/`,
    APPLICATION_SERVICE: `${MASTER_BASE}/application-services/`,
    SNAPSHOT_URL:`${MASTER_BASE}/snapshots`,
    DEV_URL: `${MASTER_BASE}/`
  }
};
