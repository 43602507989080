import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { toHttpParams, createHttpHeaders } from './http-params';

@Injectable({
    providedIn: 'root'
})
/**
 * Http service
 * Provides methods to get, put, post and delete items.
 */
export class HttpService {

    constructor(private httpClient: HttpClient) { }

    protected get(URL: string, obj: object, key: any, value: any): Observable<any> {
        const headers = createHttpHeaders();
        if (obj) { // if filter object present
            const params = toHttpParams(obj, key, value);
            return this.httpClient.get<any>(URL, { headers, params });
        }
        else {
            return this.httpClient.get<any>(URL, { headers });
        }
    }
    protected post(URL: string, obj: object): Observable<any> {
        const headers = createHttpHeaders();
        const params = toHttpParams(obj, null, null);
        return this.httpClient.post<any>(URL, params, { headers });
    }

    protected put(URL: string, obj: object, fileData: any | Blob): Observable<any> {
        if (fileData) {
            let formData: FormData;
            const options = {
                reportProgress: true,
            };
            formData = new FormData();
            formData.append('file', fileData, fileData.name);
            return this.httpClient.put<any>(URL, formData, options);
        } else {
            const headers = createHttpHeaders();
            const params = toHttpParams(obj, null, null);
            return this.httpClient.put<any>(URL, params, { headers });
        }
    }

    protected postFile(URL: string, fileData: any | Blob): Observable<any> {
        let formData: FormData;
        const options = {
            reportProgress: true,
        };
        formData = new FormData();
        formData.append('file', fileData, fileData.name);
        formData.append('projectId', localStorage.getItem('ActiveProjectId'));
        return this.httpClient.post<any>(URL, formData, options);
    }

    protected delete(URL: string, obj: any): Observable<any> {
        const options = {
            headers: createHttpHeaders(),
            body: toHttpParams(obj, null, null),
          };
        return this.httpClient.delete<any>(URL, options);
    }
}
