export enum ApplicationUrls {
  NETWORK_APPLICATION = "network-applications",
  NETWORK_APPLICATION_GROUPS = "network-application-groups",
  APPLICATION_SERVICES = "application-services",
  APPLICATION_SERVICE_GROUPS = "application-service-groups",
  DEVICES = "devices",
  DEVICE_GROUPS = "device-groups"
}

export enum ProjectUrls {
  GET_PROJECTS = 'projects',
  SETTING_URL = 'settings',
  ADD_PROJECT = 'fw-address-group?projectID=61256b8bdd59595b3f77d81a',
}

export enum FilesUrls {
  GET_FILE_LIST = 'files',
  UPLOAD_CONFIG_FILE = ''
}

export enum Analysis {
  ANALYZE = 'analyze',
  MERGE = 'merge'
}

export enum WebFilterUrls {
  GET_WEBFILTER_SETTINGS_URL = 'web-filter',
  GET_URLCATEGORIES_URL = 'url-filtering/url-filtering-rules',
  GET_FILTERING_RULES = 'url-filtering/url-filtering-rules',
  GET_CATEGORIES = 'url-filtering/categories',
  IMPORT_CATEGORIES = 'zia/import-url-categories',
  IMPORT_FILTERING_RULES = 'zia/import-url-filtering-rules',
  GET_CATCH_ALL = 'url-filtering/catch-all',
  GET_ALL_URL_FILTERING_RULES = 'url-filtering/all-url-filtering-rules',
  POST_URL_CATEGORIES_FILE_URL = 'url-category/upload',
  EXPORT = 'url-category/export'
}

export enum FirewallUrls {
  GET_ADDRESS_GROUP_URL = 'address-groups',
  GET_POLICY_URL = 'policies',
  GET_ADDRESS_OBJECT_URL = 'address-objects',
  GET_SERVICES_URL = 'services',
  GET_INTERFACE_URL = 'interfaces',
  EXPORT_SERVICE_URL = 'fw-service/export'
}

export enum MigrateURLS {
  ADDRESS_GROUPS = 'migrate-address-groups',
  POLICIES = 'migrate-firewall-policies',
  SERVICES = 'migrate-firewall-services',
}

export enum ImportURLS {
  USER_AND_GROUPS = 'import-users-and-groups',
  URL_FILTERING_RULE = 'import-url-filtering-rules',
  LOCATION_AND_GROUPS = 'import-location-and-groups',
  SYNC_FIREWALL_SERVICES = 'sync-firewall-services',
  SYNC_ADDRESS_GROUP = 'sync-address-groups',
  SYNC_APPLICATION = 'import-applications',
  DEVICE_SYNC = 'import-devices',
  TIME_INTERVAL = 'import-time-windows',
  FIREWALL_POLICY = 'sync-firewall-policies',
  SCIM_ATTRIBUTE ='zpa/sync-scim-attributes' 
}

export enum InterfacesUrls {
  GET_INTERFACES_SETTINGS_URL = '',
  GET_INTERFACES_ENTRIES_URL = '',
}


export enum UserGroupUrls {
  USERS_URL = 'zia-users',
  GROUP_URL = 'zia-groups',
  DEPARTMENT_URL = 'zia-departments',
  IMPORT_URL = 'zia/import-users',
  GET_LOCATION = 'get-locations',
  GET_LOCATION_GROUP = "get-location-groups",
  ORGANIZATIONS = "organizations",
  ORGANIZATION = "organization",
  ORGANIZATION_ID='users?organizationId',
  ADD_USER ='add-user'
}

export enum Setting {
  SETTINGS_URL = 'settings',
  TEST_URL = 'zia/test',
  TEST = 'test'
}


export enum Pending {
  PENDING_CHANGES = 'pending-changes',
  APPLY_PENDING_CHANGES = 'apply',
  PENDING_CHANGES_COUNT = "count",
  INPROGRESS_CHANGES_COUNT = "in-progress"
}

export enum scim{
  SCIM_ATTRIBUTES="zpa/scim-attributes-headers/",
}

export const COMMONKEYS = {
  ZIA: {
    AUTH_KEYS: {
      LOGIN_NAME: "loginName"
    },
    SETTINGS: {
      URL: "url",
      USERNAME: "username",
      PASSWORD: "password",
      API_KEY: "apiKey",
      TIMESTAMP: "timestamp",
    },
    TYPES: {
      CUSTOM: "CUSTOM",
      SRC: "SOURCE",
      DST: "DESTINITION",
      DST_IP: "DSTN_IP",
      DST_FQDN: "DSTN_FQDN",
    },
    POLICY: {
      ACTION_ALLOW: "ALLOW",
      ACTION_BLOCK: "BLOCK_DROP",
      ENABLED: "ENABLED",
      READ_WRITE: "READ_WRITE",
    }
  }
}