<div class="layout-breadcrumb-container flex align-items-center justify-content-between">
    <div class="layout-breadcrumb-left-items flex align-items-center">
        <a href="#" class="menu-button" (click)="appMain.onMenuButtonClick($event)"
            *ngIf="appMain.isStatic() && !appMain.isMobile()">
            <i class="pi pi-bars"></i>
        </a>

        <p-breadcrumb [model]="items" styleClass="layout-breadcrumb py-2"></p-breadcrumb>
    </div>
    <div class="layout-breadcrumb-right-items flex">
        <!-- <a tabindex="0" class="search-icon" (click)="appMain.searchActive = true; appMain.searchClick = true;">
            <i class="pi pi-search"></i>
        </a> -->

        <!-- <div class="search-wrapper" [ngClass]="{'active-search-wrapper': appMain.searchActive}">
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText placeholder="Search..." [(ngModel)]="search" (click)="appMain.searchClick=true"/>
            </span>
        </div> -->
        <p-confirmDialog #cd key="confirmSnap"  [style]="{width: '450px'}"
            acceptButtonStyleClass="p-button-text" rejectButtonStyleClass="p-button-text">
            <ng-template pTemplate="footer">
                <div class="mb-2 mt-1 note" *ngIf="accept">
                    <label for="note" class="mr-1 mb-2"> <strong>Description :</strong> </label>
                    <textarea pInputTextarea [(ngModel)]="description" class="ml-2 text-area"
                        id="note"></textarea>
                </div>
                <button type="button" pButton class="p-button-text" icon="pi pi-times" label="No"
                    (click)="cd.reject()"></button>
                <button type="button" pButton class="p-button-text" icon="pi pi-check" label="Yes"
                    [disabled]="accept && !description" (click)="cd.accept()"></button>
            </ng-template>
        </p-confirmDialog>

        <!-- <div class="snapshot mr-2">
            <span class="p-input-icon-left">
                <p-button label="Take Snapshot" icon="pi pi-camera" *ngIf="sessionService.getItem('ActiveProjectName')"
                    (click)="snapshot()"></p-button>
            </span>
        </div> -->
        <div class="search-wrapper">
            <span class="p-input-icon-left">
                <p-button label="Go To Sync" icon="pi pi-sync" *ngIf="sessionService.getItem('ActiveProjectName')"
                    (onClick)="goToSync()"></p-button>
            </span>
        </div>

        <button pButton pRipple type="button" label="Pending Changes" *ngIf="showPendingButton > 0"
            class="layout-rightmenu-button" (click)="goToPendingChanges()">
            <i class="pi pi-bell mr-4" pBadge value="{{showPendingButton}}" severity="danger">
            </i>
        </button>
        <button pButton pRipple type="button" label="Admin" *ngIf="sessionService.getItem('role') == 'SUPER_ADMIN'"
            class="layout-rightmenu-button" (click)="goToSuperAdmin()"></button>
        <!-- <button pButton pRipple type="button"  label="Sync" *ngIf="!appMain.isMobile() && showPendingButton > 0"
                class="layout-rightmenu-button"><i class="pi pi-spinner ml-4" ></i></button> -->
        <!--<button pButton pRipple type="button" icon="pi pi-bookmark" *ngIf="appMain.isMobile()"
                class="layout-rightmenu-button" (click)="appMain.onRightMenuButtonClick()"></button> -->
    </div>
</div>