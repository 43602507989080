<div class="grid p-fluid">
    <div class="col-12">
        <div class="card">
            <h5>Carousel</h5>
            <p-carousel [value]="products" [numVisible]="3" [numScroll]="3" [circular]="false" [responsiveOptions]="carouselResponsiveOptions">
                <ng-template let-product pTemplate="item">
                    <div class="product-item">
                        <div class="product-item-content">
                            <div class="mb-3">
                                <img src="assets/demo/images/product/{{product.image}}" [alt]="product.name" class="product-image" />
                            </div>
                            <div>
                                <h4 class="mb-1">{{product.name}}</h4>
                                <h6 class="mt-0 mb-3">${{product.price}}</h6>
                                <span [class]="'product-badge status-'+product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span>
                                <div class="car-buttons mt-5">
                                    <p-button type="button" styleClass="p-button p-button-rounded mr-2" icon="pi pi-search"></p-button>
                                    <p-button type="button" styleClass="p-button-success p-button-rounded mr-2" icon="pi pi-star"></p-button>
                                    <p-button type="button" styleClass="p-button-help p-button-rounded" icon="pi pi-cog"></p-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-carousel>
        </div>
    </div>

    <div class="col-12">
        <div class="card">
            <h5>Galleria</h5>
            <p-galleria [value]="images" [responsiveOptions]="galleriaResponsiveOptions"
                        [containerStyle]="{'max-width': '800px', 'margin':'auto'}" [numVisible]="7" [circular]="true">
                <ng-template pTemplate="item" let-item>
                    <img [src]="item.itemImageSrc" style="width: 100%; display: block;"/>
                </ng-template>
                <ng-template pTemplate="thumbnail" let-item>
                    <div class="grid p-nogutter justify-content-center">
                        <img [src]="item.thumbnailImageSrc" style="display: block;"/>
                    </div>
                </ng-template>
            </p-galleria>
        </div>
    </div>
</div>
