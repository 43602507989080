import { Injectable } from '@angular/core'
import { PendingChanges } from './pending-changes.service'
import { SessionService } from './session.service'

@Injectable({
	providedIn: 'root',
})
export class AppService {
	userData: any
	isProjectSelected: any
	activeProjectId: string
	menu: any
	public colorScheme: string = 'light'
	userRole = ''
	theme
	projectType

	constructor(private pendingChanges: PendingChanges, private sessionStorage: SessionService) {
		this.menu = []
		this.getInitialMenu()
	}
	getInitialMenu() {
		const menuList = [
			{
				label: 'Favorites',
				icon: 'pi pi-home',
				canHide: false,
				access: ['SUPER_ADMIN', 'NETWORK_ADMIN'],
				apps: ['ZS', 'NETSKOPE'],
				items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/co-dashboard'] }],
			},
			{
				label: 'Customer',
				icon: 'pi pi-book',
				routerLink: ['/projects'],
				canHide: false,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS', 'NETSKOPE'],
				items: [
					{
						label: 'Customer List',
						icon: 'pi pi-fw pi-align-left',
						items: [{ label: 'Project List', icon: 'pi pi-fw pi-list', routerLink: ['/projects'] }],
					},
				],
			},
		]

		this.userRole = localStorage.getItem('role')
		if (this.userRole) {
			this.userRole = this.userRole.replace(/['"]+/g, '')
		}
		this.menu = menuList
		// .filter(item => {
		//   if(item.access.indexOf(this.userRole) > -1) {
		//    return true;
		//   }
		//   return false
		// });
	}

	updateMenu(appId) {
		this.projectType = this.sessionStorage.getItem('ProjectType')
		let menuList = []

		// const Zpa: any =

		// const Netskope: any
		// const Zscaler: any

		if (this.projectType === 'FORTINET_TO_ZSCALER') {
			menuList = this.menuForZscaler(appId)
		} else if (this.projectType === 'FORTINET_TO_NETSKOPE') {
			menuList = this.menuForNetskope()
		} else if (this.projectType === 'ZPTM') {
			menuList = this.menuForZpa(appId)
		}

		this.userRole = localStorage.getItem('role')
		if (this.userRole) {
			this.userRole = this.userRole.replace(/['"]+/g, '')
		}
		this.menu = menuList.filter(item => {
			if (item.access.indexOf(this.userRole) === -1) {
				return false
			}
			return true
		})
	}

	menuForZscaler(appId) {
		const menuList = [
			{
				label: 'Favorites',
				icon: 'pi pi-home',
				canHide: false,
				routerLink: ['/co-dashboard'],
				access: ['SUPER_ADMIN', 'NETWORK_ADMIN'],
				apps: ['ZS', 'NETSKOPE'],
				items: [
					{
						label: 'Dashboard',
						icon: 'pi pi-fw pi-home',
						routerLink: ['/co-dashboard'],
						access: ['SUPER_ADMIN', 'NETWORK_ADMIN'],
						apps: ['ZS', 'NETSKOPE'],
					},
				],
			},
			{
				label: 'Customer',
				icon: 'pi pi-book',
				routerLink: ['/projects'],
				canHide: false,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [
					{
						label: 'Customer List',
						icon: 'pi pi-fw pi-align-left',
						items: [{ label: 'Project List', icon: 'pi pi-fw pi-list', routerLink: ['/projects'] }],
					},
				],
			},
			// {
			//   label: 'Projects', icon: 'pi pi-book', routerLink: ['/projects'], canHide: false,
			//   items: [
			//     { label: 'Project List', icon: 'pi pi-fw pi-list', routerLink: ['/projects'] }
			//   ]
			// },
			{
				label: 'Import',
				icon: 'pi pi-file',
				routerLink: ['/files'],
				canHide: true,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [{ label: 'File Import', icon: 'pi pi-fw pi-list', routerLink: ['/files', appId] }],
			},
			{
				label: 'URL & Cloud App Policy',
				icon: 'pi pi-globe',
				routerLink: ['/webfilter'],
				canHide: true,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [
					{
						label: 'URL Policy Dashboard',
						icon: 'pi pi-fw pi-link',
						routerLink: ['/webfilter/url-categories', appId],
					},
					{
						label: 'Zscaler URL Policies',
						icon: 'pi pi-fw pi-check-square',
						routerLink: ['/webfilter/url-filtering-rules', appId],
					},
					{ label: 'Cloud App', icon: 'pi pi-cloud', routerLink: ['/webfilter/catch-all', appId] },
					{
						label: 'Content Policy Analysis',
						icon: 'pi pi-book',
						routerLink: ['/webfilter/filtering-policies', appId],
					},
				],
			},
			{
				label: 'Firewall',
				icon: 'pi pi-shield',
				routerLink: ['/firewall'],
				canHide: true,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [
					{ label: 'Policies', icon: 'pi pi-fw pi-key', routerLink: ['/firewall/policies', appId] },
					{
						label: 'SRC_DST Addresses',
						icon: 'pi pi-fw pi-compass',
						routerLink: ['/firewall/addressGroup', appId],
					},
					{
						label: 'FGT Migrated Addresses',
						icon: 'pi pi-fw pi-id-card',
						routerLink: ['/firewall/address', appId],
					},
					{
						label: 'Services',
						icon: 'pi pi-fw pi-folder-open',
						routerLink: ['/firewall/services', appId],
					},
					// { label: 'AppID', icon: 'pi pi-fw pi-list', routerLink: ['/firewall/appId', appId] },
					{
						label: 'Interfaces',
						icon: 'pi pi-fw pi-briefcase',
						routerLink: ['/firewall/Interfaces', appId],
					},
				],
			},
			{
				label: 'User Groups',
				icon: 'pi pi-users',
				routerLink: ['/user-group'],
				canHide: true,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [
					{
						label: 'Users',
						icon: 'pi pi-fw pi-user-plus',
						routerLink: ['/user-group/users', appId],
					},
					{ label: 'User Groups', icon: 'pi pi-fw pi-users', routerLink: ['/user-group', appId] },
					{
						label: 'Departments',
						icon: 'pi pi-fw pi-tablet',
						routerLink: ['/user-group/departments', appId],
					},
				],
			},
			{
				label: 'Locations',
				icon: 'pi pi-map',
				routerLink: ['/location-group'],
				canHide: true,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [
					{ label: 'Location', icon: 'pi pi-fw pi-map-marker', routerLink: ['/location', appId] },
					{
						label: 'Location Groups',
						icon: 'pi pi-fw pi-map',
						routerLink: ['/location-group', appId],
					},
				],
			},
			{
				label: 'Network Applications',
				icon: 'pi pi-tablet',
				routerLink: ['/nw-applications'],
				canHide: true,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [
					{
						label: 'Applications',
						icon: 'pi pi-fw pi-server',
						routerLink: ['/nw-applications', appId],
					},
					{
						label: 'Application Groups',
						icon: 'pi pi-fw pi-th-large',
						routerLink: ['/nw-application-groups', appId],
					},
				],
			},
			{
				label: 'Application Services',
				icon: 'pi pi-desktop',
				routerLink: ['/application-services'],
				canHide: true,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [
					{
						label: 'Application Services',
						icon: 'pi pi-fw pi-envelope',
						routerLink: ['/application-services', appId],
					},
					{
						label: 'Application Service Groups',
						icon: 'pi pi-fw pi-envelope',
						routerLink: ['/application-service-groups', appId],
					},
				],
			},
			{
				label: 'Devices',
				icon: 'pi pi-tablet',
				routerLink: ['/devices'],
				canHide: true,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [
					{ label: 'Devices', icon: 'pi pi-fw pi-mobile', routerLink: ['/devices', appId] },
					{
						label: 'Devices Groups',
						icon: 'pi pi-fw pi-folder',
						routerLink: ['/devices-groups', appId],
					},
				],
			},
			{
				label: 'System',
				icon: 'pi pi-cog',
				routerLink: ['/system-settings'],
				canHide: true,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [
					{ label: 'Settings', icon: 'pi pi-fw pi-cog', routerLink: ['/system-settings', appId] },
					{
						label: 'Synchronize to ZScalar',
						icon: 'pi pi-fw pi-sync',
						routerLink: ['/synch-zs', appId],
					},
					{ label: 'Time Intervals', icon: 'pi pi-fw pi-clock', routerLink: ['/time', appId] },
					{ label: 'Snapshots', icon: 'pi pi-fw pi-camera', routerLink: ['/snapshot', appId] },
				],
			},
			// {
			//   label: 'System', icon: 'pi pi-cog', routerLink: ['/zpa/system-settings'], canHide: true,
			//   access: ['NETWORK_ADMIN'], apps: ['ZS'],
			//   items: [
			//     { label: 'Settings', icon: 'pi pi-fw pi-cog', routerLink: ['/zpa/system-settings', appId], access: ['NETWORK_ADMIN'], apps: ['ZS'],},
			//   ]
			// },
			{
				label: 'API Mapper',
				icon: 'pi pi-sitemap',
				routerLink: ['/mappings'],
				canHide: true,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [
					{ label: 'Config Map', icon: 'pi pi-fw pi-sitemap', routerLink: ['/mappings', appId] },
					{
						label: 'Pending Changes',
						icon: 'pi pi-fw pi-exclamation-triangle',
						routerLink: ['/pending-changes', appId],
					},
				],
			},
			{
				label: 'Analysis',
				icon: 'pi pi-exclamation-triangle',
				routerLink: ['/analysis'],
				canHide: true,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [
					{
						label: 'Policies',
						icon: 'pi pi-fw pi-key',
						routerLink: ['analysis/policy-analysis', appId],
					},
					// { label: 'Services', icon: 'pi pi-fw pi-folder-open', routerLink: ['analysis/service-analysis', appId] },
					{
						label: 'Addresses',
						icon: 'pi pi-fw pi-compass',
						routerLink: ['analysis/address-analysis', appId],
					},
					// { label: 'Address Groups', icon: 'pi pi-fw pi-compass', routerLink: ['analysis/address-group-analysis', appId] },
				],
			},
			// {
			//   label: 'UI Kit', icon: 'pi pi-fw pi-star', routerLink: ['/uikit'],
			//   items: [
			//     { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'] },
			//     { label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'] },
			//     { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel'] },
			//     { label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/uikit/invalidstate'] },
			//     { label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'], class: 'rotated-icon' },
			//     { label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'] },
			//     { label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list'] },
			//     { label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree'] },
			//     { label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel'] },
			//     { label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay'] },
			//     { label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media'] },
			//     { label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu'] },
			//     { label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message'] },
			//     { label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file'] },
			//     { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts'] },
			//     { label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc'] }
			//   ]
			// },
			// {
			//   label: 'Utilities', icon: 'pi pi-fw pi-compass', routerLink: ['utilities'],
			//   items: [
			//     { label: 'Display', icon: 'pi pi-fw pi-desktop', routerLink: ['utilities/display'] },
			//     { label: 'Elevation', icon: 'pi pi-fw pi-external-link', routerLink: ['utilities/elevation'] },
			//     { label: 'FlexBox', icon: 'pi pi-fw pi-directions', routerLink: ['utilities/flexbox'] },
			//     { label: 'Icons', icon: 'pi pi-fw pi-search', routerLink: ['utilities/icons'] },
			//     { label: 'Text', icon: 'pi pi-fw pi-pencil', routerLink: ['utilities/text'] },
			//     { label: 'Widgets', icon: 'pi pi-fw pi-star-o', routerLink: ['utilities/widgets'] },
			//     { label: 'Grid System', icon: 'pi pi-fw pi-th-large', routerLink: ['utilities/grid'] },
			//     { label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', routerLink: ['utilities/spacing'] },
			//     { label: 'Typography', icon: 'pi pi-fw pi-align-center', routerLink: ['utilities/typography'] }
			//   ]
			// },
			// {
			//   label: 'Pages', icon: 'pi pi-fw pi-briefcase', routerLink: ['/pages'],
			//   items: [
			//     { label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/crud'] },
			//     { label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/pages/calendar'] },
			//     { label: 'Timeline', icon: 'pi pi-fw pi-calendar', routerLink: ['/pages/timeline'] },
			//     { label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank' },
			//     { label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login'] },
			//     { label: 'Invoice', icon: 'pi pi-fw pi-dollar', routerLink: ['/pages/invoice'] },
			//     { label: 'Help', icon: 'pi pi-fw pi-question-circle', routerLink: ['/pages/help'] },
			//     { label: 'Error', icon: 'pi pi-fw pi-times-circle', routerLink: ['/error'] },
			//     { label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/notfound'] },
			//     { label: 'Access Denied', icon: 'pi pi-fw pi-lock', routerLink: ['/access'] },
			//     { label: 'Empty', icon: 'pi pi-fw pi-circle-off', routerLink: ['/pages/empty'] }
			//   ]
			// },
			// {
			//   label: 'Hierarchy', icon: 'pi pi-fw pi-align-left',
			//   items: [
			//     {
			//       label: 'Submenu 1', icon: 'pi pi-fw pi-align-left',
			//       items: [
			//         {
			//           label: 'Submenu 1.1', icon: 'pi pi-fw pi-align-left',
			//           items: [
			//             { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left' },
			//             { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left' },
			//             { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left' },
			//           ]
			//         },
			//         {
			//           label: 'Submenu 1.2', icon: 'pi pi-fw pi-align-left',
			//           items: [
			//             { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-align-left' }
			//           ]
			//         },
			//       ]
			//     },
			//     {
			//       label: 'Submenu 2', icon: 'pi pi-fw pi-align-left',
			//       items: [
			//         {
			//           label: 'Submenu 2.1', icon: 'pi pi-fw pi-align-left',
			//           items: [
			//             { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-align-left' },
			//             { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-align-left' },
			//           ]
			//         },
			//         {
			//           label: 'Submenu 2.2', icon: 'pi pi-fw pi-align-left',
			//           items: [
			//             { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-align-left' },
			//           ]
			//         },
			//       ]
			//     }
			//   ]
			// },
			// {
			//   label: 'Start', icon: 'pi pi-fw pi-download',
			//   items: [
			//     {
			//       label: 'Buy Now', icon: 'pi pi-fw pi-shopping-cart', url: ['https://www.primefaces.org/store']
			//     },
			//     {
			//       label: 'Documentation', icon: 'pi pi-fw pi-info-circle', routerLink: ['/documentation']
			//     }
			//   ]
			// }
		]
		return menuList
	}

	menuForNetskope() {
		const menuList = [
			{
				label: 'Favorites',
				icon: 'pi pi-home',
				canHide: false,
				routerLink: ['/co-dashboard'],
				access: ['SUPER_ADMIN', 'NETWORK_ADMIN'],
				apps: ['ZS', 'NETSKOPE'],
				items: [
					{
						label: 'Dashboard',
						icon: 'pi pi-fw pi-home',
						routerLink: ['/co-dashboard'],
						access: ['SUPER_ADMIN', 'NETWORK_ADMIN'],
						apps: ['ZS', 'NETSKOPE'],
					},
				],
			},
			{
				label: 'Customer',
				icon: 'pi pi-book',
				routerLink: ['/projects'],
				canHide: false,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [
					{
						label: 'Customer List',
						icon: 'pi pi-fw pi-align-left',
						items: [{ label: 'Project List', icon: 'pi pi-fw pi-list', routerLink: ['/projects'] }],
					},
				],
			},
		]
		return menuList
	}

	menuForZpa(appId) {
		const menuList = [
			{
				label: 'Favorites',
				icon: 'pi pi-home',
				canHide: false,
				routerLink: ['/co-dashboard'],
				access: ['SUPER_ADMIN', 'NETWORK_ADMIN'],
				apps: ['ZS', 'NETSKOPE'],
				items: [
					{
						label: 'Dashboard',
						icon: 'pi pi-fw pi-home',
						routerLink: ['/co-dashboard'],
						access: ['SUPER_ADMIN', 'NETWORK_ADMIN'],
						apps: ['ZS', 'NETSKOPE'],
					},
				],
			},
			{
				label: 'Customer',
				icon: 'pi pi-book',
				routerLink: ['/projects'],
				canHide: false,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [
					{
						label: 'Customer List',
						icon: 'pi pi-fw pi-align-left',
						items: [{ label: 'Project List', icon: 'pi pi-fw pi-list', routerLink: ['/projects'] }],
					},
				],
			},
			{
				label: 'System',
				icon: 'pi pi-cog',
				routerLink: ['/zpa/system-settings'],
				canHide: true,
				access: ['NETWORK_ADMIN'],
				apps: ['ZS'],
				items: [
					{
						label: 'Settings',
						icon: 'pi pi-fw pi-cog',
						routerLink: ['/zpa/system-settings', appId],
						access: ['NETWORK_ADMIN'],
						apps: ['ZS'],
					},
					{
						label: 'Sync ZPA',
						icon: 'pi pi-fw pi-cog',
						routerLink: ['synch-zpa/', appId],
						access: ['NETWORK_ADMIN'],
						apps: ['ZS'],
					},
					{
						label: 'Scim Attributes',
						icon: 'pi pi-align-justify',
						routerLink: ['scim-attribute/', appId],
						access: ['NETWORK_ADMIN'],
						apps: ['ZS'],
					},
				],
			},
		]
		return menuList
	}

	getPendingChanges() {
		this.pendingChanges.getPendingChanges({}).subscribe(data => {
			this.pendingChanges.isPending.next({ pendingChanges: data.pendingChanges })
		})
	}
}
