import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {DashboardDemoComponent} from './demo/view/dashboarddemo.component';
import {FormLayoutDemoComponent} from './demo/view/formlayoutdemo.component';
import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
import {MediaDemoComponent} from './demo/view/mediademo.component';
import {MenusDemoComponent} from './demo/view/menusdemo.component';
import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
import {MiscDemoComponent} from './demo/view/miscdemo.component';
import {EmptyDemoComponent} from './demo/view/emptydemo.component';
import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
import {FileDemoComponent} from './demo/view/filedemo.component';
import {DocumentationComponent} from './demo/view/documentation.component';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {InputDemoComponent} from './demo/view/inputdemo.component';
import {FloatLabelDemoComponent} from './demo/view/floatlabeldemo.component';
import {InvalidStateDemoComponent} from './demo/view/invalidstatedemo.component';
import {ButtonDemoComponent} from './demo/view/buttondemo.component';
import {TableDemoComponent} from './demo/view/tabledemo.component';
import {ListDemoComponent} from './demo/view/listdemo.component';
import {TreeDemoComponent} from './demo/view/treedemo.component';
import {DisplayComponent} from './utilities/display.component';
import {ElevationComponent} from './utilities/elevation.component';
import {FlexboxComponent} from './utilities/flexbox.component';
import {GridComponent} from './utilities/grid.component';
import {IconsComponent} from './utilities/icons.component';
import {WidgetsComponent} from './utilities/widgets.component';
import {SpacingComponent} from './utilities/spacing.component';
import {TypographyComponent} from './utilities/typography.component';
import {TextComponent} from './utilities/text.component';
import {AppCrudComponent} from './pages/app.crud.component';
import {AppCalendarComponent} from './pages/app.calendar.component';
import {AppTimelineDemoComponent} from './pages/app.timelinedemo.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';
import {AppHelpComponent} from './pages/app.help.component';
import {AuthGuard} from './core/auth/auth.guard';
import {ComanageDashboardComponent} from './demo/view/comanagedashboard.component';
import {AdminAuthGuard} from './core/auth/admin.auth.gaurd';


@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'projects',
                        loadChildren: () => import('./pages/projects/projects.module').then(m => m.ProjectsModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'files/:projectId',
                        data: {title: 'Files'},
                        loadChildren: () => import('./pages/files/files.module').then(m => m.FilesModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'system-settings/:projectId',
                        data: {title: 'System Settings'},
                        loadChildren: () => import('./pages/system-settings/system-settings.module').then(m => m.SystemSettingsModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'mappings/:projectId',
                        data: {title: 'Mappings'},
                        loadChildren: () => import('./pages/mappings/mappings.module').then(m => m.MappingsModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'pending-changes/:projectId',
                        data: {title: 'Pending Changes'},
                        loadChildren: () => import('./pages/pending-changes/pending-changes.module').then(m => m.PendingChangesModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'snapshot-details/:projectId',
                        data: { title: 'Snapshot Details' },
                        loadChildren: () => import('./pages/snapshot/snapshot.module').then(m => m.SnapshotModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'user-group/:projectId',
                        data: {title: 'User Groups'},
                        loadChildren: () => import('./pages/user-group/user-group/user-group.module').then(m => m.UserGroupModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'user-group/departments/:projectId',
                        data: {title: 'Departments'},
                        loadChildren: () => import('./pages/user-group/department/department.module').then(m => m.DepartmentModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'user-group/users/:projectId',
                        data: {title: 'Users'},
                        loadChildren: () => import('./pages/user-group/users/users.module').then(m => m.UserGroupUsersModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'location/:projectId',
                        data: {title: 'Location'},
                        loadChildren: () => import('./pages/user-group/location/location.module').then(m => m.LocationModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'location-group/:projectId',
                        data: {title: 'Location Group'},
                        loadChildren: () => import('./pages/user-group/location-group/location-group.module').then(m => m.LocationGroupModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'firewall/policies/:projectId',
                        data: {title: 'Policies'},
                        loadChildren: () => import('./pages/firewall/policies/policies.module').then(m => m.PoliciesModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'firewall/address/:projectId',
                        data: {title: 'Address'},
                        loadChildren: () => import('./pages/firewall/address/address.module').then(m => m.AddressModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'firewall/appId/:projectId',
                        data: {title: 'AppID'},
                        loadChildren: () => import('./pages/firewall/app-id/app-id.module').then(m => m.AppIDModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'firewall/Interfaces/:projectId',
                        data: {title: 'Interfaces'},
                        loadChildren: () => import('./pages/firewall/interfaces/interfaces.module').then(m => m.InterfacesModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'firewall/services/:projectId',
                        data: {title: 'Services'},
                        loadChildren: () => import('./pages/firewall/services/services.module').then(m => m.ServicesModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'firewall/addressGroup/:projectId',
                        data: {title: 'Address Group'},
                        loadChildren: () => import('./pages/firewall/address-group/address-group.module').then(m => m.AddressGroupModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'webfilter/url-categories/:projectId',
                        data: {title: 'URL Categories'},
                        loadChildren: () => import('./pages/webfilter/url-categories/url-categories.module').then(m => m.UrlCategoriesModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'webfilter/catch-all/:projectId',
                        data: {title: 'URL Categories'},
                        loadChildren: () => import('./pages/webfilter/catch-all-filter/catch-all-filter.module').then(m => m.CatchAllFilterModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'webfilter/filtering-policies/:projectId',
                        data: {title: 'URL Categories'},
                        loadChildren: () => import('./pages/webfilter/filter-older/filter-older.module').then(m => m.FilterOlderModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'webfilter/url-filtering-rules/:projectId',
                        data: {title: 'URL Categories'},
                        loadChildren: () => import('./pages/webfilter/url-filtering-rules/url-filtering-rules.module').then(m => m.UrlFilteringRulesModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'analysis/service-analysis/:projectId',
                        data: {title: 'Service Analysis'},
                        loadChildren: () => import('./pages/analysis/service-analysis/service-analysis.module').then(m => m.ServiceAnalysisModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'analysis/address-analysis/:projectId',
                        data: {title: 'Address Analysis'},
                        loadChildren: () => import('./pages/analysis/address-analysis/address-analysis.module').then(m => m.AddressAnalysisModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'analysis/policy-analysis/:projectId',
                        data: {title: 'Policy Analysis'},
                        loadChildren: () => import('./pages/analysis/policy-analysis/policy-analysis.module').then(m => m.PolicyAnalysisModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'analysis/address-group-analysis/:projectId',
                        data: {title: 'Policy Analysis'},
                        loadChildren: () => import('./pages/analysis/address-group-analysis/address-group-analysis.module').then(m => m.AddressGroupAnalysisModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'synch-zs/:projectId',
                        data: {title: 'ZSCALAR SYNCH'},
                        loadChildren: () => import('./pages/synch-zs/synch-zs.module').then(m => m.SynchZSModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'nw-applications/:projectId',
                        data: {title: 'Network Applications'},
                        loadChildren: () => import('./pages/nw-application/nw-application.module').then(m => m.NwApplicationModule),
                        canActivate: [AuthGuard]

                    },
                    {
                        path: 'nw-application-groups/:projectId',
                        data: {title: 'Network Application Groups'},
                        loadChildren: () => import('./pages/nw-application-groups/nw-application-groups.module').then(m => m.NwApplicationGroupsModule),
                        canActivate: [AuthGuard]

                    },
                    {
                        path: 'application-services/:projectId',
                        data: {title: 'Applications Services'},
                        loadChildren: () => import('./pages/application-services/application-services.module').then(m => m.ApplicationServicesModule),
                        canActivate: [AuthGuard]

                    },
                    {
                        path: 'application-service-groups/:projectId',
                        data: {title: 'Network Applications Service Groups'},
                        loadChildren: () => import('./pages/application-service-groups/application-service-groups.module').then(m => m.ApplicationServiceGroupsModule),
                        canActivate: [AuthGuard]

                    },
                    {
                        path: 'devices-groups/:projectId',
                        data: {title: 'Devices Groups'},
                        loadChildren: () => import('./pages/devices-groups/devices-groups.module').then(m => m.DevicesGroupsModule),
                        canActivate: [AuthGuard]

                    },
                    {
                        path: 'devices/:projectId',
                        data: {title: 'Devices'},
                        loadChildren: () => import('./pages/devices/devices.module').then(m => m.DevicesModule),
                        canActivate: [AuthGuard]

                    },
                    {
                        path: 'time/:projectId',
                        data: {title: 'Time'},
                        loadChildren: () => import('./pages/time/time.module').then(m => m.TimeModule),
                        canActivate: [AuthGuard]

                    },
                    {path: 'co-dashboard', component: ComanageDashboardComponent, canActivate: [AuthGuard]},
                    {
                        path: 'accounts',
                        data: {title: 'accounts', role: ['SUPER_ADMIN']},
                        loadChildren: () => import('./pages/accounts/accounts.module').then(m => m.AccountsModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'organization/:id/users',
                        data: {title: 'accounts', role: ['SUPER_ADMIN']},
                        loadChildren: () => import('./pages/organization-user/organization-user.module')
                            .then(m => m.OrganizationUserModule),
                        canActivate: [AuthGuard]
                    },
                    
                    {
                        path: 'snapshot/:id',
                        data: {title: 'snapshot'},
                        loadChildren: () => import('./pages/snapshot/snapshot.module')
                            .then(m => m.SnapshotModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'snapshot/:projectId/:id',
                        data: {title: 'snapshot'},
                        loadChildren: () => import('./pages/snapshotdiff/snapshotdiff.module')
                            .then(m => m.SnapshotDiffModule),
                        canActivate: [AuthGuard]
                    },

                    // zpa Routes
                    {
                        path: 'zpa/system-settings/:projectId',
                        data: {title: 'System Setting'},
                        loadChildren: () => import('./pages/ZPA/system-settings/zpa-system-settings.module')
                            .then(m => m.SystemSettingsModule),
                        canActivate: [AuthGuard]
                    },

                    {
                        path: 'synch-zpa/:projectId',
                        data: {title: 'ZPA SYNCH'},
                        loadChildren: () => import('./pages/ZPA/synch-zpa/synch-zpa.module').then(m => m.SynchZPAModule),
                        canActivate: [AuthGuard]
                    },

                    {
                        path: 'scim-attribute/:projectId',
                        data: {title: 'SCIM ATTRIBUTES'},
                        loadChildren: () => import('./pages/ZPA/scim-attributes/scim-attributes.module').then(m => m.ScimAttributesModule),
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'scim-attribute/:projectId',
                        data: {title: 'Scim Attributes'},
                        loadChildren: () => import('./pages/ZPA/scim-attributes/scim-attributes.module').then(m => m.ScimAttributesModule),
                        canActivate: [AuthGuard]
                    },


                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'co-dashboard'
                    },
                    {path: 'access', component: AppAccessdeniedComponent},
                    {path: 'dashboard', component: DashboardDemoComponent},
                    {path: 'co-dashboard', component: ComanageDashboardComponent},
                    {path: 'uikit/formlayout', component: FormLayoutDemoComponent},
                    {path: 'uikit/floatlabel', component: FloatLabelDemoComponent},
                    {path: 'uikit/invalidstate', component: InvalidStateDemoComponent},
                    {path: 'uikit/input', component: InputDemoComponent},
                    {path: 'uikit/button', component: ButtonDemoComponent},
                    {path: 'uikit/table', component: TableDemoComponent},
                    {path: 'uikit/list', component: ListDemoComponent},
                    {path: 'uikit/tree', component: TreeDemoComponent},
                    {path: 'uikit/panel', component: PanelsDemoComponent},
                    {path: 'uikit/overlay', component: OverlaysDemoComponent},
                    {path: 'uikit/menu', component: MenusDemoComponent},
                    {path: 'uikit/media', component: MediaDemoComponent},
                    {path: 'uikit/message', component: MessagesDemoComponent},
                    {path: 'uikit/misc', component: MiscDemoComponent},
                    {path: 'uikit/charts', component: ChartsDemoComponent},
                    {path: 'uikit/file', component: FileDemoComponent},
                    {path: 'utilities/display', component: DisplayComponent},
                    {path: 'utilities/elevation', component: ElevationComponent},
                    {path: 'utilities/flexbox', component: FlexboxComponent},
                    {path: 'utilities/grid', component: GridComponent},
                    {path: 'utilities/icons', component: IconsComponent},
                    {path: 'utilities/widgets', component: WidgetsComponent},
                    {path: 'utilities/spacing', component: SpacingComponent},
                    {path: 'utilities/typography', component: TypographyComponent},
                    {path: 'utilities/text', component: TextComponent},
                    {path: 'pages/crud', component: AppCrudComponent},
                    {path: 'pages/calendar', component: AppCalendarComponent},
                    {path: 'pages/timeline', component: AppTimelineDemoComponent},
                    {path: 'pages/invoice', component: AppInvoiceComponent},
                    {path: 'pages/help', component: AppHelpComponent},
                    {path: 'pages/empty', component: EmptyDemoComponent},
                    {path: 'documentation', component: DocumentationComponent}
                ]
            },
            {path: 'error', component: AppErrorComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            {path: 'login', component: AppLoginComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
