import { Component, OnDestroy } from '@angular/core';
import { AppBreadcrumbService } from './app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { ConfirmationService, MenuItem } from 'primeng/api';
import {AppMainComponent} from './app.main.component';
import { PendingChanges } from './core/services/pending-changes.service';
import { Router } from '@angular/router';
import { SessionService } from './core/services/session.service';
import { SnapshotService } from './pages/snapshot/snapshot.service';
import { AlertService, IAlertType } from './core/services/alert-service';
import { Snapshot } from './pages/snapshot/snapshot.model';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html'
})
export class AppBreadcrumbComponent implements OnDestroy {

    subscription: Subscription;
    items: MenuItem[];
    home: MenuItem;

    search: string;
    showPendingButton = 0;
    description:any;
    projectId: number;
    accept: boolean;
    constructor(
        public breadcrumbService: AppBreadcrumbService, public appMain: AppMainComponent, private confirmationService: ConfirmationService, 
        private pendingChanges: PendingChanges, private router: Router, public sessionService: SessionService,
        private snapshotService: SnapshotService, private alertService: AlertService) {
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });
        this.pendingChanges.isPending.subscribe((changes) => {
            this.showPendingButton = changes.pendingChanges;
        });
        this.home = { icon: 'pi pi-home', routerLink: '/' };
    }

    snapshot() {
        this.accept=true;
        const data = {
            projectId: this.sessionService.getItem('ActiveProjectId'),
            description: this.description
        }
        this.confirmationService.confirm({
            key:'confirmSnap',
            header: 'Confirm',
            message: 'Are you sure you want to take snapshot?',
            accept: () => {
                //Actual logic to perform a confirmation
                this.snapshotService.AddSnapshot( {
                    projectId: this.sessionService.getItem('ActiveProjectId'),
                    description: this.description
                }).subscribe(result => {
                    this.alertService.show({
                        severity: IAlertType.SUCCESS,
                        detail: "Snap Saved",
                        summary: 'Success',
                    });
                }, () => {
                    this.alertService.show({
                        severity: IAlertType.ERROR,
                        detail: "Request Failed",
                        summary: 'Error',
                    });
                })
            }
        });
    }

    hideConfirm() {
		this.accept = false
		this.description;
	}

    goToPendingChanges() {
        this.router.navigate(['/pending-changes', this.sessionService.getItem("ActiveProjectId")])
    }

    goToSync() {
        this.router.navigate(['/synch-zs', this.sessionService.getItem("ActiveProjectId")])
    }

    goToSuperAdmin() {
        this.router.navigate(['/accounts'])
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.pendingChanges.isPending.unsubscribe();
    }
}
