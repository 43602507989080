import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Pending, ProjectUrls, Setting } from '../http/http-api-url';
import { HttpService } from '../http/http.service';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class PendingChanges extends HttpService {
    public isPending = new BehaviorSubject<any>(false);
    constructor(http: HttpClient, public session: SessionService) {
        super(http);
      }

    getPendingChanges(filterObj: any): Observable<any> {
        return this.get(
          `${environment.baseUrls.PENDING_CHANGE_URL}${Pending.PENDING_CHANGES_COUNT}?projectId=${this.session.getItem('ActiveProjectId')}`,
          filterObj,
          null,
          null
        );
      }

}
