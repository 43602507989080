import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
// import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.auth.getIdTokenClaims$().pipe(
      mergeMap(token => {
        let tokenReq = null;
        if(token) {
          tokenReq = req.clone({
            // setHeaders: { idToken: `${token.__raw}` }
            setHeaders: { Authorization: `Bearer ${token.__raw}` }
          });
        }
 
        return next.handle(tokenReq);
      }),
      catchError(err => throwError(err))
    );
  }
}
